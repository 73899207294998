@import 'assets/scss/theme';



.pill {
  padding: 5px;
  margin-right: 5px;
  font: inherit;
  font-size: 0.5em;
  text-transform: uppercase;
  border-radius: .3rem;
  border: none;
  color: #ffffff;
  text-align: center;

  @include themed($themes) {
    background-color: t("group-color");
    color: t("text-white");
    box-shadow: t("shadow") t("shadow-color");
  }

  &:last-child {
    margin-right: 0;
  }

  &.theme {
    @include themed($themes) {
      background-color: t("theme-color");
    }
  }

  &.warning {
    @include themed($themes) {
      background-color: t("orange-color");
    }
  }

  &.danger {
    @include themed($themes) {
      background-color: t("red-color");
    }
  }

  &.success {
    @include themed($themes) {
      background-color: t("green-color");
    }
  }
}

button.pill {
  cursor: pointer;
}


