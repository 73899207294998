@import 'assets/scss/theme';
@import 'react-day-picker/dist/style.css';


.rdp {
	margin: 0;
	width: fit-content;
	font-family: $font-lato;

	&-caption {
		border-radius: 0.3rem 0.3rem 0 0;
		padding: 0 1rem * 2/3;
	}

	&-caption,
	&-head {
		@include themed($themes) {
			background-color: t('border-theme');
			color: t('text-white');
		}
	}

	&-caption_label,
	&-head_cell {
		font-weight: 400;
		font-size: 1.6rem;
		text-transform: none;
		&:first-child {
			padding-left: 1rem;
		}

		&:last-child {
			padding-right: 1rem;
		}
	}

	&-tbody {
		@include themed($themes) {
			background-color: t('element-background');
		}
	}

	// start setting the border and the padding of the table
	.rdp-table {
		border-spacing: 0;
		border-collapse: separate;
		border-radius: 0 0 0.3rem 0.3rem;
	}

	.rdp-row:last-child {
		td.rdp-cell:first-child {
			border-radius: 0 0 0 0.3rem;
		}

		td.rdp-cell:last-child {
			border-radius: 0 0 0.3rem 0;
		}
	}

	&-row:last-child .rdp-cell {
		@include themed($themes) {
			border-color: t('border-theme');
		}

		padding-bottom: 1rem;
		border-bottom: .1rem solid;
	}

	&-row:first-child .rdp-cell {
		padding-top: 1rem;
	}

	&-cell {
		width: 1.5rem !important;
		height: 1.5rem !important;
		&:last-child {
			@include themed($themes) {
				border-color: t('border-theme');
			}

			padding-right: 1rem;
			border-right: .1rem solid;
		}

		&:first-child {
			@include themed($themes) {
				border-color: t('border-theme');
			}

			padding-left: 1rem;
			border-left: .1rem solid;
		}
	}

	&-error {
		@include themed($themes) {
			color: t('text-red');
		}
	}

	// end setting the border of the table

	&-nav_button {
		&:hover {
			@include themed($themes) {
				background-color: t('border-theme');
				border-color: t('text-gray');
			}

			background-color: transparent;
			border-radius: 0.3rem;
		}
	}

	&-day {
		@include themed($themes) {
			color: t('text');
		}

		&:hover:not(.rdp-day_selected) {
			@include themed($themes) {
				border-color: t('border-gray');
				background-color: t('element-background');
			}

			background-color: transparent;
			border-radius: 0.3rem;
		}

		&_today {
			@include themed($themes) {
				color: t('datepicker-today');
			}
		}

		&_selected,
		&_selected.rdp-day_range_start.rdp-day_range_end {
			@include themed($themes) {
				background-color: t('border-theme');
				color: t('text-white');
			}

			border-radius: 0.3rem;
		}

		&_range {
			&_middle {
				border-radius: 0;
			}
		}

		&_weekend {
			@include themed($themes) {
				color: t('text-gray');
			}
		}

		&_disabled {
			@include themed($themes) {
				color: t('text-gray');
			}
		}
	}

	&-button {
		&:focus-visible:not([disabled]) {
			@include themed($themes) {
				background-color: t('border-theme');
				color: t('text-white');
			}

			border: none;
			outline: none;
			border-radius: 0.3rem;
		}

		&:hover:not([disabled]):not(.rdp-day_selected) {
			background-color: transparent;
		}
	}
}
