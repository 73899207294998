@import "assets/scss/theme";

.moving-mission {
	.streaks {
		.activities {
			.card__body {
				justify-content: unset;
				flex-direction: column;
			}

			&__date-selector {
				position: absolute;
				display: flex;
				align-items: center;
				top: 2rem;
				right: 2rem;

				.arrow {
					margin: 0 .5rem;
				}
			}

			&__graph {
				display: flex;
				justify-content: space-between;
				height: 15rem;
				margin-top: 1.5rem;
				width: 100%;

				&-bar {
					margin-top: 1.5rem;
					width: 1rem;
					display: flex;
					flex-direction: column;
					align-items: center;

					&--segment {
						margin-bottom: .5rem;
						width: 100%;
						min-height: 1.5rem;
						border-radius: .5rem;
					}

					&--day {
						font-size: 1.2rem;
						margin-top: .5rem;
						font-weight: 300;

						@include themed($themes) {
							color: t("text");
						}
					}
				}
			}

			&__legend {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				font-size: 1.2rem;
				gap: 1.5rem;

				&-item {
					display: flex;
					align-items: center;

					&__color {
						width: 1rem;
						height: 1rem;
						border-radius: .3rem;
						margin-right: .5rem;
					}
				}
			}
		}
	}
}
