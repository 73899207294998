@import "assets/scss/theme";

.toasts {
  @media (max-width: 768px) {
    width: 100%;
  }
  box-sizing: border-box;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 10103;
  top: 0;
  right: 0;
  padding: 0.8rem;

  .toast__container {
    transition: height 120ms ease 100ms;
    border-radius: 0.3rem;

    .toast__notification {
      @media (max-width: 768px) {
        width: 100%;
      }
      @media (min-width: 768px) {
        width: 36rem;
      }
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.176) 0 0.3rem 0.8rem;
      margin-bottom: 0.8rem;
      transform: translate3d(0, 0, 0);
      transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s,
        opacity 220ms ease 0s;

      .toast__icon {
        border-radius: 0.3rem 0 0 0.3rem;
        flex-shrink: 0;
        padding-bottom: 0.8rem;
        padding-top: 0.8rem;
        position: relative;
        text-align: center;
        width: 3rem;
        overflow: hidden;

        svg {
          font-size: 1.6rem;
          display: inline-block;
          vertical-align: text-top;
        }

        .toast__countdown {
          background-color: rgba(0, 0, 0, 0.1);
          bottom: 0;
          height: 0;
          left: 0;
          opacity: 0;
          position: absolute;
          width: 100%;
        }
      }

      .toast__body {
        -webkit-box-flex: 1;
        flex-grow: 1;
        font-size: 1.4rem;
        line-height: 1.4;
        min-height: 4rem;
        padding: 0.8rem 1.2rem;
      }

      .toast__actions {
        cursor: pointer;
        flex-shrink: 0;
        opacity: 0.5;
        padding: 0.8rem 1.2rem;
        transition: opacity 150ms ease 0s;

        svg {
          height: 1.5rem;
          display: inline-block;
          vertical-align: text-top;
        }

        .toast__dismiss {
          clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
          height: 0.1rem;
          position: absolute;
          white-space: nowrap;
          width: 0.1rem;
          border-width: 0;
          border-style: initial;
          border-color: initial;
          border-image: initial;
          overflow: hidden;
          padding: 0;
        }
      }
    }

    &.success {
      @include themed($themes) {
        background-color: t("toast-background-success");
        color: t("text-green");
      }

      .toast__icon {
        @include themed($themes) {
          background-color: t("toast-icon-success");
          color: t("toast-background-success");
        }
      }
    }

    &.warning {
      @include themed($themes) {
        background-color: t("toast-background-warning");
        color: t("text-orange");
      }

      .toast__icon {
        @include themed($themes) {
          background-color: t("toast-icon-warning");
          color: t("toast-background-warning");
        }
      }
    }

    &.error {
      @include themed($themes) {
        background-color: t("toast-background-danger");
        color: t("dark-text");
      }

      .toast__icon {
        @include themed($themes) {
          background-color: t("toast-icon-danger");
          color: t("toast-background-danger");
        }
      }
    }

    &.info {
      @include themed($themes) {
        background-color: t("toast-background-info");
        color: t("text-blue");
      }

      .toast__icon {
        @include themed($themes) {
          background-color: t("toast-icon-info");
          color: t("toast-background-info");
        }
      }
    }
  }
}

@keyframes animation-timeout {
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
}
