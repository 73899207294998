@import 'assets/scss/theme';

.well-being {
  &__registrations {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .card {
      flex: 1 1 100%;
      box-sizing: border-box;

      &__urgent {
        @include themed($themes) {
          background: t('toast-background-danger');
        }

        .card__header h2 {
          @include themed($themes) {
            color: t('text-red-card');
          }
        }
      }

      &__attend {
        @include themed($themes) {
          background: t('toast-background-warning');
        }

        .card__header h2 {
          @include themed($themes) {
            color: t('text-orange-card');
          }
        }
      }

      /*&__done {
        @include themed($themes) {
          background: t('toast-background-succes');
        }

        .card__header h2 {
          @include themed($themes) {
            color: t('text-green-card');
          }
        }
      }*/

      .question {
        margin-bottom: 1.5rem;

        .S {
          font-size: 3rem;

          .green {
            @include themed($themes) {
              color: t('text-green');
            }
          }

          .orange {
            @include themed($themes) {
              color: t('text-orange');
            }
          }

          .red {
            @include themed($themes) {
              color: t('text-red');
            }
          }
        }
      }

      @media (min-width: 768px) {
      }

      @media (min-width: 992px) {
        flex: 1 1 calc(calc(100% / 2) - 2rem);
      }

      @media (min-width: 1200px) {
        flex: 1 1 calc(calc(100% / 3) - 2rem);
      }
    }
  }
}