@import 'assets/scss/theme';

.form-field__upload-file-section {
	& .form-field__upload-file {
		display: flex;
		justify-content: space-between;
		margin: 10px 0;

		& .form-field__upload-file-label {
			display: flex;
			align-items: center;
			gap: 5px;

			@include themed($themes) {
				color: t('text');
			}

			&--clickable {
				cursor: pointer;
			}
		}

		& .form-field__upload-file-remove-button {
			background: transparent;
			@include themed($themes) {
				color: t('text-red');
			}
		}

		&__buttons {
			display: flex;
			justify-content: flex-end;
			gap: 0.5rem;
		}

		&__extra {
			@include themed($themes) {
				color: t('text-theme');
			}

			cursor: pointer;

			&.disabled {
				@include themed($themes) {
					color: t('btn-disabled-text');
				}

				cursor: not-allowed;
			}
		}
	}

	button {
		display: flex;
		align-items: center;
		cursor: pointer;
		border: none;
		padding: 0.3rem 0.5rem;
		border-radius: 0.3rem;
	}

	& .form-field__upload-buttons {
		display: flex;
		justify-content: flex-end;

		& .form-field__upload-remove-all-button {
			gap: 5px;
			background: transparent;

			@include themed($themes) {
				color: t('text-red');
			}
		}
	}
}

.form-field {
	&__control {
		&.form-field__upload {
			border-style: dashed;
			padding: 3rem .5rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 5px;
			font-weight: 400;

			input {
				position: absolute;
				top: 0;
				opacity: 0;
				width: 100%;
				height: 100%;

				&:hover {
					cursor: pointer;
				}
			}

			.form-field__label {
				@include themed($themes) {
					color: t('text-theme');
				}

				display: flex;
				align-items: center;
				font-size: 1.25em;
				font-weight: 400;

				& .form-field__icon {
					display: inline;
				}
			}

			.form-field__sub-label {
				@include themed($themes) {
					color: t('text');
				}

				font-size: 1.1em;
				font-weight: 300;
			}
		}
	}

	&--disabled {
		.form-field__control {
			&.form-field__upload {
				.form-field__label {
					@include themed($themes) {
						color: t('text-gray');
					}
				}

				.form-field__sub-label {
					@include themed($themes) {
						color: t('text-gray');
					}
				}
			}
		}
	}

	&--has-errors {
		.form-field__control {
			&.form-field__upload {
				.form-field__label {
					@include themed($themes) {
						color: t('text-red');
					}
				}
			}
		}
	}
}
