@import 'assets/scss/theme';

.select {
	width: 100%;

	.select__control {
		background-color: transparent;
		border: none;
		margin-right: -10px;
		min-height: 1em;
		margin-top: -1px;
		margin-bottom: -1px;

		.select__value-container--has-value {
			font-family: $font-lato, sans-serif;
			font-weight: 400;
		}

		&:focus,
		&.select__control--is-focused {
			outline: 0;
			box-shadow: none;
		}

		.select__value-container {
			max-height: 10.3rem;
			overflow: auto;

			div {
				@include themed($themes) {
					color: t('text') !important;
				}
			}
			padding: 0;

			.select__input-container {
				margin: 0;
				padding: 0;
				overflow: hidden;

				input.select__input {
					@include themed($themes) {
						color: t('text') !important;
					}

					font-family: $font-lato, sans-serif;
					font-weight: 400 !important;
					font-size: 18px !important;
					width: auto !important;
				}
			}

			.select__placeholder {
				@include themed($themes) {
					color: t('text-gray');
				}

				font-family: $font-montserrat;
				font-size: 16px;
				font-weight: 200;
			}

			.select__multi-value {
				@include themed($themes) {
					background-color: t('background');
					border-color: t('text-gray');
				}
				font-size: large;
				border-radius: 5px;
				border: 1px solid;

				.select__multi-value__label {
					@include themed($themes) {
						color: t('text-theme');
					}
					padding: 0 5px;
				}
				.select__multi-value__remove {
					@include themed($themes) {
						color: t('text-theme');
					}
					&:hover {
						@include themed($themes) {
							background-color: t('hover-background');
						}
					}
				}
			}
		}

		.select__indicators {
			.select__indicator-separator {
				margin: 0;
			}

			.select__indicator.select__dropdown-indicator {
				padding: 0 0.5rem;
			}


			.select__clear-indicator {
				padding: 0 0.5rem;
			}
		}
	}

	.form-field__content {
		width: 100%;
	}
}

.select__menu-portal,
.select__menu {
	font-family: $font-lato, sans-serif;
	font-size: 14px;
	font-weight: 400;

	@include themed($themes) {
		color: t('text');
		border-color: t('light-gray');
		background-color: t('background');
	}

	.select__option--is-focused {
		@include themed($themes) {
			background-color: t('hover-background');
		}
	}

	.select__option--is-selected {
		@include themed($themes) {
			background-color: t('theme-color');
			color: t('white-text');
		}
	}
}
