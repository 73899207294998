@import "assets/scss/theme";

.btn {
  font-family: $font-montserrat;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0 30px;
  border-radius: 3px;
  cursor: pointer;
  min-height: 30px;
  transition: all 0.3s ease-in;
  text-decoration: none;

  &--block {
    display: block;
    width: 100%;
    padding: 0;
  }

  &--small {
    padding: 2.5px 5px;
    font-size: 13px;
    min-height: 25px;
  }

  &--primary {
    @include themed($themes) {
      background-color: t("btn-primary-color");
      border-color: t("btn-primary-color");
      color: t("btn-primary-text-color");
    }

    &:not(:disabled):not(.disabled):not(.success):not(.error):hover {
      @include themed($themes) {
        background-color: t("btn-hover-color");
        border-color: t("btn-hover-color");
      }
    }

    &.active {
      @include themed($themes) {
        background-color: t("btn-hover-color");
        border-color: t("btn-hover-color");
        color: t("btn-primary-text-color");
      }
    }


    &.success {
      @include themed($themes) {
        background-color: t("btn-success");
        border-color: t("btn-success");
        color: t("theme");
      }

      &:hover {
        @include themed($themes) {
          background-color: t("btn-success-hover");
          border-color: t("btn-success-hover");
          color: t("theme");
        }
      }

    }

    &.error,
    &.danger {
      @include themed($themes) {
        background-color: t("btn-error");
        border-color: t("btn-error");
        color: t("theme");
      }

      &:hover {
        @include themed($themes) {
          background-color: t("btn-error-hover");
          border-color: t("btn-error-hover");
          color: t("theme");
        }
      }
    }

    &:disabled,
    &.disabled,
    &:disabled:hover,
    &.disabled:hover {
      cursor: not-allowed;

      @include themed($themes) {
        background-color: t("btn-disabled");
        border-color: t("btn-disabled");
        color: t("btn-disabled-text");
      }

      &:hover {
        box-shadow: none;
      }
    }

  }


  &--secondary {
    @include themed($themes) {
      border-color: t("btn-secondary-color");
      color: t("btn-secondary-text-color");
    }

    background-color: transparent;

    &:hover {
      @include themed($themes) {
        border-color: t("btn-hover-color");
        color: t("btn-hover-color-text");
      }
    }

    &.active {
      @include themed($themes) {
        border-color: t("btn-hover-color");
        color: t("btn-hover-color");
      }
    }


    &.success {
      @include themed($themes) {
        border-color: t("btn-success");
        color: t("btn-success");
      }

      &:hover {
        @include themed($themes) {
          border-color: t("btn-success-hover");
          color: t("btn-success-hover");
        }
      }
    }

    &.danger,
    &.error {
      @include themed($themes) {
        background-color: t("theme");
        border-color: t("btn-error");
        color: t("btn-error");
      }

      &:hover {
        @include themed($themes) {
          background-color: t("theme");
          border-color: t("btn-error-hover");
          color: t("btn-error-hover");
        }
      }
    }

    &:disabled,
    &.disabled,
    &:disabled:hover,
    &.disabled:hover {
      cursor: not-allowed;

      @include themed($themes) {
        border-color: t("btn-disabled-text");
        color: t("btn-disabled-text");
      }

      &:hover {
        box-shadow: none;
      }
    }

  }

  &--link {
    @include themed($themes) {
      color: t("text-link");
    }

    display: inline;
    font-size: unset;
    font-weight: unset;
    text-align: left;
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: unset;
    text-transform: unset;
    cursor: pointer;
    height: unset;
    text-decoration: none;
    transition: all 0.3s ease-in;

    &:hover,
    &.active {
      @include themed($themes) {
        color: t("text-link-hover");
      }

      background-color: transparent;
    }

    &:active {
      @include themed($themes) {
        color: t("text-link-hover");
      }

      background-color: transparent;
    }

    &:disabled,
    &.disabled,
    &:disabled:hover,
    &.disabled:hover {
      cursor: not-allowed;

      @include themed($themes) {
        color: t("text-gray");
      }

    }

  }

  &__group {
    display: inline-flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 0.2rem;
    margin: 0.5rem 0;

    .btn {
      flex: 1 1 auto;
      border-radius: 0;
      height: auto;

      &:first-child {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
      }

      &:last-child {
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
      }

      &.btn--small {
        padding: 0.3rem 0.8rem;
      }

      &.btn--block {
        width: auto;
      }

      &.btn--link {
        margin-left: 1.5rem;
        font-size: 1.8rem;

        &.btn--small {
          font-size: 1.3rem;
        }
      }
    }

    &.full--flex {
      display: flex;
      flex-flow: row wrap;
      grid-gap: 0.5rem;

      .btn {
        flex: 1;

        &.btn--small {
          padding: 0.3rem 0.8rem;
        }
      }
    }
  }

}