@import '../../../../assets/scss/theme';

.form-radio {
	margin: 0 0 30px 0;

	&__control {
		label {
			display: block;
			position: relative;
			font-family: $font-montserrat, sans-serif;
			padding-left: 3.2rem;
			margin: .5rem 0;

			&:before,
			&:after {
				@include themed($themes) {
					border-color: t('border-theme');
					background-color: t('element-background');
				}

				content: '';
				display: inline-block;
				position: absolute;
				top: .15rem;
				left: 0;
				width: 1.7rem;
				height: 1.7rem;
				margin-right: 1.5rem;
				border: .1rem solid;
				border-radius: 1.7rem;
				cursor: pointer;
			}

			&:after {
				@include themed($themes) {
					border-color: t('border-theme');
					background-color: t('border-theme');
				}

				transform: scale(0);
				transition: all .4s;
			}

			&.form-field--has-value {
				&:after {
					transform: scale(1);
					transition: all .4s;
				}
			}
		}

		input[type='radio'] {
			display: none;
		}
	}

	&.form-field {
		&--has-errors {
			.form-radio__control {
				label {
					&:before {
						@include themed($themes) {
							border-color: t('border-red');
							background: t('text-invalid-feedback-background');
						}
					}

					&.form-field--has-value:after {
						@include themed($themes) {
							border-color: t('border-red');
							background: t('border-red');
						}
					}
				}
			}
		}

		&--disabled {
			.form-radio__control {
				label {
					cursor: not-allowed;

					&:before,
					&:after {
						cursor: not-allowed;
					}

					&:before {
						@include themed($themes) {
							border-color: t('border-gray');
						}
					}

					&:after {
						@include themed($themes) {
							border-color: t('input-disabled');
							background-color: t('input-disabled');
						}

						transform: scale(1);
						transition: all .4s;
					}

					&.form-field--has-value {
						&:before {
							@include themed($themes) {
								border-color: t('border-gray');
							}
						}

						&:after {
							@include themed($themes) {
								border-color: t('border-gray');
								background-color: t('border-gray');
							}

							transform: scale(1);
							transition: all .4s;
						}
					}
				}
			}
		}
	}
}
