@import '../../assets/scss/variables';

@mixin media-breakpoint($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}


@function breakpoint($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, 0);
}

main {
	padding-top: 12.7rem;
	padding-bottom: 5rem;
}

.container {
	width: 100%;
	padding-right: $gutter + px;
	padding-left: $gutter + px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	@include media-breakpoint(sm) {
        padding-left: 0;
        padding-right: 0;
    }
	@each $breakpoint, $container-max-width in $container-max-widths {
		@include media-breakpoint($breakpoint, $grid-breakpoints) {
			max-width: $container-max-width;
		}
	}

	&.home {
		position: relative;
		@media (min-width: 768px) {
			padding-left: 27rem;
			padding-right: 0;
		}

		@media (min-width: 992px) {
			padding-left: 27rem;
			padding-right: 27rem;
		}
		@media (min-width: 1024px) {
			padding-right: 0;
			padding-left: 0;
			display: grid;
			grid-template-columns: 1fr 3fr;
			grid-template-rows: auto;
			position: relative;
		}
	}
}

.container-fluid {
	width: 100%;
	padding-right: $gutter + px;
	padding-left: $gutter + px;
	margin-right: auto;
	margin-left: auto;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -$gutter + px;
	margin-right: -$gutter + px;

	.no-gutters {
		margin-right: 0;
		margin-left: 0;

		> .col,
		> [class*='col-'] {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

%grid-column {
	position: relative;
	width: 100%;
	padding-left: $gutter + px;
	padding-right: $gutter + px;
}

@each $breakpoint in map-keys($grid-breakpoints) {
	$infix: if(
		breakpoint($breakpoint, $grid-breakpoints) == null,
		'',
		'-#{$breakpoint}'
	);

	@for $i from 1 through $grid-columns {
		.col#{$infix}-#{$i} {
			@extend %grid-column;
		}
	}

	.col#{$infix},
	.col#{$infix}-auto {
		@extend %grid-column;
	}

	@include media-breakpoint($breakpoint, $grid-breakpoints) {
		.col#{$infix} {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
		}
		.col#{$infix}-auto {
			flex: 0 0 auto;
			width: auto;
			max-width: 100%;
		}

		@for $i from 1 through $grid-columns {
			.col#{$infix}-#{$i} {
				flex: 0 0 percentage($i / $grid-columns);
				max-width: percentage($i / $grid-columns);
			}
		}

		.order#{$infix}-first {
			order: -1;
		}

		.order#{$infix}-last {
			order: $grid-columns + 1;
		}

		@for $i from 0 through $grid-columns {
			.order#{$infix}-#{$i} {
				order: $i;
			}
		}

		@for $i from 0 through ($grid-columns - 1) {
			@if not($infix == '' and $i == 0) {
				.offset#{$infix}-#{$i} {
					$num: $i / $grid-columns;
					margin-left: if($num == 0, 0, percentage($num));
				}
			}
		}

		@if not($infix == '') {
			.d#{$infix}-none {
				display: none;
			}
		}

		@if not($infix == '') {
			.d#{$infix}-block {
				display: block !important;
			}
		}
	}
}
