@import "assets/scss/theme";

.pagination {
  font-size: 1.55rem;
  margin: 3rem 0;
  display: block;

  &__pages {
    list-style: none;
    margin: .5rem 0 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0 .3rem;


      a {
        padding: 0 .3rem;
      }

      &.break {
        padding: 0;
        letter-spacing: 0.25em;
      }

      &.selected {
        font-weight: bold;

        a {
          @include themed($themes) {
            color: t("btn-hover-color");
          }
        }
      }

      &.disabled {
        a {
          cursor: not-allowed;

          @include themed($themes) {
            color: t("btn-disabled-text")
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;

    &__pages {
      margin: 0 0 0 3rem;
    }
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
}