@import 'assets/scss/theme';

.form-toggle {
    margin: 0 0 30px 0;

    &__control {
        label {
            position: relative;
            font-family: $font-montserrat, sans-serif;
            padding-left: 4.7rem;

            @include themed($themes) {
                color: t('text');
            }

            &:before {
                content: '';
                position: absolute;
                border: .1rem solid;
                left: 0;
                top: .7rem;
                width: 3.2rem;
                height: .55rem;
                border-radius: .5rem;
                cursor: pointer;

                @include themed($themes) {
                    background-color: t('border-gray');;
                    border-color: t('border-gray');;
                }
            }

            &:after {
                content: '';
                position: absolute;
                border: 1px solid;
                top: .19rem;
                left: 0rem;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: .5rem;
                transition: all 0.2s ease-in-out;

                @include themed($themes) {
                    border-color: t('border-red');
                    background-color: t('border-red');
                }
            }
        }

        input[type='checkbox'] {
            display: none;
        }
    }

    &.form-field {
        &--has-value .form-toggle__control {
            label {
                &:after {
                    left: 1.6rem;

                    @include themed($themes) {
                        background-color: t('border-green');
                        border-color: t('border-green');
                    }
                }
            }
        }

        &--has-errors {
            .form-toggle__control {
                label {
                    &:before {
                        @include themed($themes) {
                            border-color: t('border-red');
                            background: t('toast-background-danger');
                        }
                    }
                }
            }

            &.form-field--has-value {
                .form-toggle__control {
                    label {
                        &:after {
                            @include themed($themes) {
                                border-color: t('border-red');
                                background: t('border-red');
                            }
                        }
                    }
                }
            }
        }

        &--disabled {
            .form-toggle__control {
                label {
                    cursor: not-allowed;

                    &:before,
                    &:after {
                        cursor: not-allowed;
                    }

                    &:after {
                        @include themed($themes) {
                            border-color: t('input-disabled');
                            background-color: t('input-disabled');
                        }
                    }
                }
            }

            &.form-field--has-value .form-toggle__control {
                label {
                    &:after {
                        @include themed($themes) {
                            border-color: t('border-gray');
                            background-color: t('border-gray');
                        }
                    }
                }
            }
        }
    }
}