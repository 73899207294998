@import "assets/scss/theme";

.entity {
    .card {
        &__body > :last-child {
            margin-bottom: 0;
        }
    }

    .link_form {
        .col-lg-6 .form-field {
            margin-bottom: 0;
        }

        .col-lg-12.form-field {
            margin: 0;

            button {
                margin-top: 1.5rem;
            }
        }
    }

    .alert_comment {
        display: flex;

        svg {
            height: 4.25rem;
            margin: .5rem 1rem .5rem 0;

            @include themed($themes) {
                color: t('text-orange');
            }
        }
    }

    .info_comment {
        display: flex;
        justify-content: space-between;

        svg {
            margin: .2rem 1rem .2rem 0;

            @include themed($themes) {
                color: t('text-orange');
            }
        }
    }

    .attachment-info {
        @include themed($themes) {
            color: t('text-theme');
            border-color: t('theme-color');
            box-shadow: t('shadow') t('shadow-color');
            background-color: t('element-background');
        }

        border: 1px solid;
        position: absolute;
        bottom: 3rem;
        right: 0;
        margin: 0;
        border-radius: 1rem;
        font-size: 1.2rem;
        font-weight: 400;
        list-style: none;
        padding: 1rem 1rem 1rem 3rem;

        svg {
            position: absolute;
            margin-top: 3px;
            left: 1rem;
            width: 1.2rem;
            text-align: center;
        }
    }

    .period {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .add-date-info {
            &__day {
                display: flex;
                flex-wrap: wrap;
                gap: .8rem;
                max-height: 35rem;
                padding-bottom: .7rem;
                overflow: auto;

                &--item {
                    display: flex;
                    flex: 30% 1 0;
                    flex-direction: column;
                    padding: 1rem;
                    border-radius: .3rem;
                    border: 0.1rem solid;

                    @include themed($themes) {
                        background-color: t("element-background");
                        border-color: t("border-gray");
                        box-shadow: t("shadow") t("shadow-color");
                    }

                    >div:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media (min-width: 675px) {
            flex-wrap: nowrap;
        }

        @media (min-width: 768px) {
            flex-wrap: wrap;
        }

        @media (min-width: 992px) {
            flex-wrap: nowrap;
        }
    }
}
