@import 'variables';

$light: (
				background: $light-background,
				element-background: $light-element-background,
				lighter-gray: $lighter-gray,
				lightest-gray: $lightest-gray,
				backdrop: rgba($light-background, 0.8),
				backdrop-busy: rgba($light-background, 0.4),

				background-green: $light-green-background,

				sidebar-backcolor: darken($light-theme-color, 20),

				sidebar-handle: darken($light-theme-color, 10%),
				sidebar-handle-hover: darken($light-theme-color, 30),
				sidebar-text: $white,
				sidebar-icon: darken($light-theme-color, 2),

				sidebar-active-back: darken($light-theme-color, 45),
				sidebar-active: $light-theme-color,
				hover-background: $rose-quartz,
				active-background: darken($light-theme-color, 10),
				//opaque-background: $opaque-light-background,

				theme-color: $light-theme-color,
				group-color: $light-group,
				red-color: $light-red,
				green-color: $light-green,
				orange-color: $light-orange,
				//secondary-theme-color: $light-orange,
				//icon-contrast: $light-theme-color,
				//login-modal-background: $light-login-modal-background,

				text: $light-text,
				text-white: $white,
				text-theme: $light-theme-color,
				text-link: $light-theme-color,
				text-link-hover: darken($light-theme-color, 10),
				text-gray: $light-gray,
				text-red: $light-red,
				text-error: $dark-red,
				text-red-card: $light-red,
				text-blue: $light-blue,
				text-blue-card: $light-blue,
				text-green: $light-green,
				text-green-card: $light-green,
				text-group: $light-group,
				text-group-card: $light-group,
				text-orange: $light-orange,
				text-orange-card: $light-orange,
				text-background: rgba($light-theme-color, 0.1),
				text-contrast: $light-theme-color,
				text-gray-contrast: $darker-gray,
				text-invalid-feedback-background: $light-red-background,

				//border-white: $white,
				//border-black: $light-text,
				//border-card: $light-gray,
				border-gray: $light-gray,
				border-theme: $light-theme-color,
				//border-theme-light: $light-element-background,
				border-red: $light-red,
				//border-blue: $light-blue,
				border-green: $light-green,
				//border-group: $light-group,
				//border-dark: $dark-theme-color,
				border-bright: $bright-green-light,

				shadow: 0 0.3rem 0.6rem,
				shadow-color: rgba($black, 0.16),
				//light-shadow-color: rgba($dark-shadow, 0.13),

				btn-primary-color: $light-theme-color,
				btn-primary-text-color: $white,
				btn-success: $light-green,
				btn-success-hover: $light-green-button,
				btn-error: $light-red,
				btn-error-hover: $light-red-button,
				btn-secondary-color: $light-theme-color,
				btn-secondary-text-color: $light-theme-color,
				btn-link-color: $light-theme-color,
				btn-hover-color: darken($light-theme-color, 10),
				btn-hover-color-text: darken($light-theme-color, 10),
				btn-disabled: $lightest-gray,
				btn-disabled-border: $light-gray,
				btn-disabled-text: $light-gray,
				btn-info: $light-theme-color,

				toast-background-success: $light-green-background,
				toast-icon-success: $light-green-medium,
				toast-background-warning: $light-orange-background,
				toast-icon-warning: $light-orange-medium,
				toast-background-danger: $light-red-background,
				toast-icon-danger: $light-red-medium,
				toast-background-info: $light-blue-background,
				toast-icon-info: $light-blue-medium,

				input-error: $light-red,
				input-primary: $light-theme-color,
				input-disabled: $lightest-gray,

				color-animation: colors-light,

				//image-opacity: 1,

				//socials-teams: $light-teams,
				//socials-facebook: $light-facebook,
				//socials-twitter: $light-twitter,
				//socials-linkedin: $light-linkedin,
				//socials-instagram: $light-instagram,
				//socials-youtube: $light-youtube,
				//socials-secondary: $light-element-background,

				//scrollbar-color: $darker-theme-color,

				//timesheet-item: $light-timesheet-item,
				//notifs-border: darken($light-theme-color, 10),
				//notifs-arrow:  $light-element-background,

				datepicker-today: $light-theme-color,


				moving-mission-gold: $moving-mission-gold,
				moving-mission-silver: $moving-mission-silver,
				moving-mission-bronze: $moving-mission-bronze,

				//lightbox-backdrop: $lightbox-backdrop-light,

				//pinned-shadow: 0 0.6rem 1.2rem #33330399,
				//pinned-background: $light-element-background,
				//important-background: $light-theme-color,

				//autograph-background: $light-element-background



				graph1: $graph1-light,
				graph2: $graph2-light,
				graph3: $graph3-light,
				graph4: $graph4-light,
				graph5: $graph5-light,
				graph6: $graph6-light,
				graph7: $graph7-light,
				graph8: $graph8-light,
				graph9: $graph9-light,
				graph10: $graph10-light,
				graph11: $graph11-light,
				graph12: $graph12-light,
				graph13: $graph13-light,
				graph14: $graph14-light,
				graph15: $graph15-light,
				graph16: $graph16-light,
				graph17: $graph17-light,
				graph18: $graph18-light,
);

$dark: (
				background: $dark-background,
				element-background: $dark-element-background,
				lighter-gray: $lighter-gray,
				lightest-gray: $lightest-gray,
				backdrop: rgba($dark-background, 0.8),
				backdrop-busy: rgba($dark-background, 0.4),

				background-green: $dark-green-background,

				sidebar-backcolor: darken($dark-theme-color, 20),

				sidebar-handle: darken($dark-theme-color, 10%),
				sidebar-handle-hover: darken($dark-theme-color, 30),
				sidebar-text: $dark-text,
				sidebar-icon: darken($dark-theme-color, 2),

				sidebar-active-back: darken($dark-theme-color, 15),
				sidebar-active: $dark-theme-color,
				hover-background: darken($rose-quartz, 95),
				active-background: darken($dark-theme-color, 10),
	//opaque-background: $opaque-light-background,

				theme-color: $dark-theme-color,
				group-color: $dark-group,
				red-color: $dark-red,
				green-color: $dark-green,
				orange-color: $dark-orange,
	//secondary-theme-color: $light-orange,
	//icon-contrast: $light-theme-color,
	//login-modal-background: $light-login-modal-background,

				text: $dark-text,
				text-white: $dark-text,
				text-theme: $dark-text,
				text-link: lighten($dark-text, 10),
				text-link-hover: darken($dark-text, 10),
				text-gray: $dark-gray,
				text-red: $lighter-dark-red-background,
				text-error: $dark-text,
				text-red-card: $dark-text,
				text-blue: $dark-blue,
				text-blue-card: $dark-text,
				text-green: $dark-green,
				text-green-card: $dark-text,
				text-group: $dark-group,
				text-group-card: $dark-text,
				text-orange: $dark-orange,
				text-orange-card: $dark-text,
				text-background: rgba($dark-text, 0.05),
				text-contrast: $dark-text,
				text-gray-contrast: $light-gray,
				text-invalid-feedback-background: $dark-red-background,

	//border-white: $white,
	//border-black: $light-text,
	//border-card: $light-gray,
				border-gray: $dark-gray,
				border-theme: $dark-theme-color,
	//border-theme-light: $light-element-background,
				border-red: $dark-red,
	//border-blue: $light-blue,
				border-green: $dark-green,
	//border-group: $light-group,
	//border-dark: $dark-theme-color,
				border-bright: $bright-green-dark,

				shadow: 0 0.3rem 0.6rem,
				shadow-color: rgba($black, 0.16),
	//light-shadow-color: rgba($dark-shadow, 0.13),

				btn-primary-color: $dark-theme-color,
				btn-primary-text-color: $dark-text,
				btn-secondary-color: $dark-theme-color,
				btn-secondary-text-color: $dark-text,
				btn-link-color: $dark-text,
				btn-hover-color: darken($dark-theme-color, 15),
				btn-hover-color-text: $dark-gray,
				btn-success: $dark-green,
				btn-success-hover: $dark-green-button,
				btn-error: $dark-red,
				btn-error-hover: $dark-red-button,
				btn-disabled: $dark-element-background,
				btn-disabled-border: $dark-theme-color,
				btn-disabled-text: $darker-gray,
				btn-info: $dark-text,

				toast-background-success: $dark-green-background,
				toast-icon-success: $dark-green-medium,
				toast-background-warning: $dark-orange-background,
				toast-icon-warning: $dark-orange-medium,
				toast-background-danger: $lighter-dark-red-background,
				toast-icon-danger: $dark-red-medium,
				toast-background-info: $dark-blue-background,
				toast-icon-info: $dark-blue-medium,

				input-error: $dark-red,
				input-primary: $dark-theme-color,
				input-disabled: $darker-gray,

				color-animation: colors-dark,

	//image-opacity: 1,

	//socials-teams: $light-teams,
	//socials-facebook: $light-facebook,
	//socials-twitter: $light-twitter,
	//socials-linkedin: $light-linkedin,
	//socials-instagram: $light-instagram,
	//socials-youtube: $light-youtube,
	//socials-secondary: $light-element-background,

	//scrollbar-color: $darker-theme-color,

	//timesheet-item: $light-timesheet-item,
	//notifs-border: darken($light-theme-color, 10),
	//notifs-arrow:  $light-element-background,

				datepicker-today: $dark-theme-color,


				moving-mission-gold: $moving-mission-gold,
				moving-mission-silver: $moving-mission-silver,
				moving-mission-bronze: $moving-mission-bronze,

	//lightbox-backdrop: $lightbox-backdrop-light,

	//pinned-shadow: 0 0.6rem 1.2rem #33330399,
	//pinned-background: $light-element-background,
	//important-background: $light-theme-color,

	//autograph-background: $light-element-background



				/*background: $dark-background,
				active-background: $dark-mauve,
				hover-background: $dark-element-background,
				opaque-background: $opaque-dark-background,
				element-background: $dark-element-background,
				background-green: $dark-green-background,
				backdrop: rgba($dark-background, 0.8),
				theme-color: $dark-theme-color,
				secondary-theme-color: $dark-orange,
				icon-contrast: $dark-text,
				lightest-gray: $lightest-gray,
				login-modal-background: $dark-login-modal-background,

				text: $dark-text,
				text-white: $dark-text,
				text-theme: $dark-text,
				text-link: lighten($dark-text, 10),
				text-link-hover: darken($dark-text, 10),
				text-gray: $dark-gray,
				text-red: $dark-red,
				text-error: $dark-text,
				text-red-card: $dark-text,
				text-blue: $dark-blue,
				text-blue-card: $dark-text,
				text-green: $dark-green,
				text-green-card: $dark-text,
				text-group: $dark-group,
				text-group-card: $dark-text,
				text-orange: $dark-orange,
				text-orange-card: $dark-text,
				text-background: rgba($dark-text, 0.05),
				text-contrast: $dark-text,
				text-gray-contrast: $light-gray,
				text-invalid-feedback-background: $dark-red-background,

				border-white: $dark-gray,
				border-black: $dark-gray,
				border-gray: $dark-gray,
				border-card: $dark-theme-color,
				border-theme: $dark-theme-color,
				border-theme-light: $dark-theme-color,
				border-red: $dark-red,
				border-blue: $dark-blue,
				border-green: $light-green-medium,
				border-group: $dark-group,

				shadow: 0 0.3rem 0.6rem,
				shadow-color: rgba($black, 0.16),
				btn-primary-color: $dark-theme-color,
				btn-primary-text-color: $dark-text,
				btn-secondary-color: $dark-theme-color,
				btn-secondary-text-color: $dark-text,
				btn-link-color: $dark-text,
				btn-hover-color: darken($dark-theme-color, 15),
				btn-hover-color-text: $dark-gray,
				btn-success: $dark-green,
				btn-success-hover: $dark-green-button,
				btn-error: $dark-red,
				btn-error-hover: $dark-red-button,
				btn-disabled: $dark-element-background,
				btn-disabled-border: $dark-theme-color,
				btn-disabled-text: $darker-gray,
				btn-info: $dark-text,
				toast-background-success: $dark-green-background,
				toast-icon-success: $dark-green-medium,
				toast-background-warning: $dark-orange-background,
				toast-icon-warning: $dark-orange-medium,
				toast-background-danger: $lighter-dark-red-background,
				toast-icon-danger: $dark-red-medium,
				toast-background-info: $dark-blue-background,
				toast-icon-info: $dark-blue-medium,

				checkbox-error: $dark-red,
				checkbox-primary-color: $dark-theme-color,
				checkbox-disabled: $darker-gray,

				color-animation: colors-dark,

				image-opacity: 0.75,

				socials-teams: $dark-teams,
				socials-facebook: $dark-facebook,
				socials-twitter: $dark-twitter,
				socials-linkedin: $dark-linkedin,
				socials-instagram: $dark-instagram,
				socials-youtube: $dark-youtube,
				socials-secondary: $dark-text,

				scrollbar-color: lighten($dark-theme-color, 10),

				timesheet-item: $dark-timesheet-item,
				notifs-arrow: lighten($dark-theme-color, 10),
				notifs-border: lighten($dark-theme-color, 10),
				datepicker-today: lighten($dark-theme-color, 20),

				moving-mission-gold: $moving-mission-gold-dark,
				moving-mission-silver: $moving-mission-silver-dark,
				moving-mission-bronze: $moving-mission-bronze-dark,

				lightbox-backdrop: $lightbox-backdrop-dark,

				pinned-shadow: 0 0 0 #000,
				pinned-background: $dark-theme-color,
				pinned-sticker-height: 2.8rem,
				pinned-sticker-top: 0.5px,
				pinned-sticker-border: $light-element-background,
				important-background: $dark-group,
				important-sticker-border: $dark-theme-color,
				important-sticker-height: 2.9rem,
				important-sticker-top: 0,

				autograph-background: $gray*/
);

$themes: (
				light: $light,
				dark: $dark,
);

@mixin themed($themes) {
	@each $theme, $map in $themes {
		.theme--#{$theme} & {
			$theme-map: (
			) !global;

			@each $key, $submap in $map {
				$value: map-get(map-get($themes, $theme), '#{$key}'
				);
				$theme-map: map-merge($theme-map,
								($key: $value,
								)) !global;
			}

			@content;
			$theme-map: null !global;
		}
	}
}

@mixin base($themes) {
	@each $theme, $map in $themes {
		&.theme--#{$theme} {
			$theme-map: (
			) !global;

			@each $key, $submap in $map {
				$value: map-get(map-get($themes, $theme), '#{$key}'
				);
				$theme-map: map-merge($theme-map,
								($key: $value,
								)) !global;
			}

			@content;
			$theme-map: null !global;
		}
	}
}

@function t($key) {
	@return map-get($theme-map, $key);
}