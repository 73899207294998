@import 'assets/scss/theme';

.form-field {
	@media (min-height: 300px) {
		margin-bottom: 1rem;
	}

	@media (min-height: 350px) {
		margin-bottom: 1.5rem;
	}

	position: relative;
	margin-top: 0.5rem;

	label {
		@include themed($themes) {
			color: t('text-theme');
		}

		display: flex;
		justify-content: space-between;
		font-family: $font-montserrat, sans-serif;
		background: none;
		font-size: 0.9em;
		font-weight: 500;
		margin-left: 10px;

		code {
			@include themed($themes) {
				color: t('text');
			}

			font-weight: lighter;
		}
	}

	&__description,
	&__invalid-feedback {
		@include themed($themes) {
			color: t('text');
		}

		font-family: $font-montserrat, sans-serif;
		font-weight: 200;
		font-size: 0.9em;
		margin-left: 10px;
		white-space: pre-wrap;
	}

	&__invalid-feedback {
		@include themed($themes) {
			color: t('text-red');
		}
	}

	&__control {
		@include themed($themes) {
			border-color: t('border-gray');
			background-color: t('element-background');
		}

		position: relative;
		border: 1px solid;
		font-family: $font-lato, sans-serif;
		font-weight: lighter;
		background: none;
		font-size: 1em;
		padding: 0.6rem 1rem;
		margin: 2px 0;
		display: flex;
		justify-content: space-between;
		border-radius: 6px;
		transition: color 300ms ease;

		input,
		textarea {
			@include themed($themes) {
				color: t('text');
			}

			display: block;
			width: 100%;
			font-family: $font-lato, sans-serif;
			font-size: 18px;
			background: none;
			border: none;
			padding: 0;
			resize: vertical;

			&:focus {
				outline: none;
				box-shadow: none;
			}

			&[type=time] {
				resize: none;

				&::-webkit-calendar-picker-indicator,
				&::-webkit-inner-spin-button,
				&::-webkit-resizer {
					display: none;
				}

				&::-webkit-datetime-edit-hour-field,
				&::-webkit-datetime-edit-minute-field {
					padding: 0 .2rem;

					&:focus {
						border-radius: .3rem;
						@include themed($themes) {
							background-color: t('hover-background');
							color: t('text');
						}
					}
				}
			}
		}

		&__icon {
			@include themed($themes) {
				color: t('text-gray');
			}

			margin-right: 0.5rem;
			padding-top: 2px;
		}

		&__action {
			@include themed($themes) {
				color: t('text-white');
				background-color: t('btn-primary-color');
			}

			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 0 5px 5px 0;
			margin: -7px -11px -7px 0;
			width: 4rem;
			cursor: pointer;
		}

		/*.form-field__content {
			&:disabled,
			&[readonly] {
				@include themed($themes) {
					color: t('text-gray');
				}
			}
		}

		.textarea {
			resize: none;
		}*/
	}

	&--has-value {
		.form-field__control {
			@include themed($themes) {
				color: t('text');
				border-color: t('border-theme');
			}

			&__icon {
				@include themed($themes) {
					color: t('text-theme');
				}
			}
		}
	}

	&--focused {
		.form-field__control {
			@include themed($themes) {
				color: t('text');
				border-color: t('border-theme');
			}

			box-shadow: inset 2px 0 10px 0 rgba($light-theme-color, 0.3);

			&__icon {
				@include themed($themes) {
					color: t('text-theme');
				}
			}
		}
	}


	&--disabled {
		cursor: not-allowed;

		label {
			@include themed($themes) {
				color: t('text');
			}

			> span {
				@include themed($themes) {
					color: t('text');
				}
			}
		}

		.form-field__control {
			@include themed($themes) {
				color: t('text-gray');
				border-color: t('input-disabled');
				background-color: t('input-disabled');
			}

			input,
			textarea {
				@include themed($themes) {
					color: t('text-gray');
					cursor: not-allowed;
				}
			}

			.form-field__input {
				cursor: not-allowed;
			}

			&__icon {
				@include themed($themes) {
					color: t('text-gray');
				}
			}

			&__action {
                @include themed($themes) {
                    background-color: t('btn-disabled-border');
					color: t('btn-disabled');
                }

                cursor: not-allowed;
            }

			&__description {
				@include themed($themes) {
					color: t('text');
				}
			}
		}
	}

	&--has-errors {
		label {
			@include themed($themes) {
				color: t('text-red');
			}
		}

		.form-field__control {
			@include themed($themes) {
				background-color: t('text-invalid-feedback-background');
				border-color: t('text-red');
			}

			&__icon {
				@include themed($themes) {
					color: t('text-red');
				}
			}

			&__action {
				@include themed($themes) {
					color: t('text-invalid-feedback-background');
					background-color: t('text-red');
					border-color: t('border-red');
				}
			}
		}
	}

	&--hide-layout {
		.form-field__control {
			background: transparent;
			border: none;
			padding: 0;
			font: unset;
		}
	}

}

::placeholder {
	@include themed($themes) {
		color: t('text-gray');
	}

	font-family: 'Montserrat', sans-serif;
	font-weight: 200;
	font-size: 1.6rem;
}

:-ms-input-placeholder {
	@include themed($themes) {
		color: t('text-gray');
	}
}

::-ms-input-placeholder {
	@include themed($themes) {
		color: t('text-gray');
	}
}

/*.radioButton {
	padding-top: 1rem;
	display: flex;
	align-items: center;
	gap: 1em;
	height: 2em;
	width: 15em;
	margin: 0.5em 0 0.5em 2rem;
	font-style: italic;
	letter-spacing: 0.1em;

	@include themed($themes) {
		border-color: t('border-theme');
	}
}

// remove arrows from number input
/* Chrome, Safari, Edge, Opera */
/*input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
/*input[type='number'] {
	-moz-appearance: textfield;
}

input[type='radio'] {
	@include themed($themes) {
		background-color: t('light-element-background');
	}

	appearance: none;
	margin: 0;
	font: inherit;
	color: currentColor;
	width: 1.15em;
	height: 1.15em;
	border: 0.15em solid;
	border-radius: 0.15em;
	display: grid;
	place-content: center;

	@include themed($themes) {
		border-color: t('border-theme');
	}

	&:hover {
		cursor: pointer;
	}
}

input[type='radio']::before {
	content: '';
	width: 1.15em;
	height: 1.15em;
	border-radius: 0.15em;
	transform: scale(0);
	transition: 0.4s transform ease-out;

	@include themed($themes) {
		box-shadow: inset 1em 1.15em t('theme-color');
	}
}

input[type='radio']:checked::before {
	transform: scale(1);
}

.theme--dark .form-checkbox label:after {
	@include themed($themes) {
		border-color: t('border-white');
	}
}
*/