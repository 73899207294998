@import 'assets/scss/theme';

.range-picker {
    @include themed($themes) {
        color: t('text-gray');
    }

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    font-weight: 400;
    font-size: 1.2em;
    font-family: $font-lato;

    .hour {
        text-align: center;
        height: 1.2em;
    }

    &:focus {
        .separator {
            @include themed($themes) {
                color: t('text');
            }
        }

        outline: none;
    }
}

.fixed-width {
    width: 22em;
}