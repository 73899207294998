@import "../../../assets/scss/theme";

.list-item.entity {
  @include themed($themes) {
    background-color: t("toast-background-danger");
  }

  &.published {
    @include themed($themes) {
      background-color: t("toast-background-success");
    }
  }

  &.unpublished {
    @include themed($themes) {
      background-color: t("toast-background-warning");
    }
  }
}

.empty-list {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 300;
  margin: 10rem 0;
  color: #c6c6c6;
}
