@import "assets/scss/theme";

.navbar {
  @include themed($themes) {
    background-color: t("element-background");
    border-color: t("border-gray");
  }

  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 5.5rem;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid;
  z-index: 1020;

  &__brand {
    display: inline-block;
    width: 22.5rem;
    height: 5.5rem;
    padding: 0.8rem 1.92rem;
    margin-right: 0;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;

      path {
        @include themed($themes) {
          fill: t("text-theme");
        }
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;

    &-item {
      position: relative;
      margin: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;

      a,
      button {
        @include themed($themes) {
          color: t("text-theme") !important;
        }

        font-size: 1.4rem;
        padding-top: 0;
        padding-bottom: 0;
        background: 0;
        border: 0;
        cursor: pointer;
      }
    }
  }
}
