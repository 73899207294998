@import "assets/scss/theme";

.moving-mission {
	.streaks {
		.card__content_wrapper {
			min-height: 51.5rem;
			overflow: hidden;
		}

		.card__body {
			display: flex;
			justify-content: space-between;
			flex-direction: column;

			@media (min-width: 600px) {
				flex-direction: row;
			}

			@media (min-width: 768px) {
				flex-direction: column;
			}

			@media (min-width: 1200px) {
				flex-direction: row;
			}
		}

		ul {
			margin: 0 0 2rem;
			padding: 0;
			list-style: none;

			li {
				margin-bottom: 1.7rem;

				svg {
					margin-right: 1rem;
					color: #ffffff;
				}

				.gold {
					@include themed($themes) {
						color: t("moving-mission-gold");
					}
				}

				.silver {
					@include themed($themes) {
						color: t("moving-mission-silver");
					}
				}

				.bronze {
					@include themed($themes) {
						color: t("moving-mission-bronze");
					}
				}
			}
		}

		&__sports {
			display: flex;
			flex-wrap: wrap;
			gap: 1rem;
			max-height: 45rem;
			overflow: auto;

			@media (min-width: 600px) {
				position: absolute;
				right: 2rem;
				top: 2rem;
				width: 17rem;
			}

			@media (min-width: 768px) {
				position: unset;
				width: unset;
				height: 35rem;
			}

			@media (min-width: 1200px) {
				position: absolute;
				width: 17rem;
				height: unset;
			}

			&::-webkit-scrollbar {
				display: none;
			}

			&-item {
				width: 8rem;
				height: 8rem;
				border-radius: .3rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;
				cursor: pointer;


				@include themed($themes) {
					box-shadow: t("shadow") t("shadow-color");
					background: t("theme-color");
					color: t("text-white");
				}

				svg {
					font-size: 3rem;
					max-width: 100%;
					max-height: 100%;
					transition: opacity 0.3s ease-in-out;
				}

				span {
					position: absolute;
					opacity: 0;
					font-size: 1.2rem;
					transition: opacity 0.3s ease-in-out;
				}

				&:hover {
					span {
						opacity: 1;
					}

					svg {
						opacity: 0;
					}
				}
			}
		}
	}

	.employees {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		overflow: auto;
		margin-bottom: 1.5rem;

		@media (min-width: 768px) {
			max-height: 96.5rem;
		}

		@media (min-width: 1200px) {
			max-height: 51.5rem;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		&__detail {
			width: calc(50% - .5rem);
			height: 12rem;
			border-radius: .3rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			cursor: pointer;

			background-color: #835e78;
			padding: .5rem;
			text-align: center;

			@include themed($themes) {
				background: t("theme-color");
				color: t("text-white");
			}

			svg {
				font-size: 3rem;
				max-width: 100%;
				max-height: 100%;
			}

			&-contact {
				width: 100%;
				margin-top: 1rem;
				font-size: 1.2rem;

				&_name {
					font-weight: bold;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					width: 100%;
				}
			}
		}
	}

	.profile {
		border: 1px solid;
		border-radius: 3px;
		margin-bottom: 1.5rem;

		overflow: visible;

		&::-webkit-scrollbar {
			display: none;
		}

		@include themed($themes) {
			background-color: t("element-background");
			border-color: t("border-theme");
			box-shadow: t("shadow") t("shadow-color");
		}

		&__info {
			text-align: center;

			@include themed($themes) {
				color: t("text-theme");
			}

			svg {
				padding: 3rem 2rem 0 2rem;
				border-radius: 100%;
				font-size: 8rem;
				margin-bottom: 1rem;
				margin-top: 4rem;

				@include themed($themes) {
					color: t("text-white");
					background-color: t("theme-color");
				}
			}

			&-name {
				font-size: 2.2rem;
			}

			&-department {
				margin-bottom: .5rem;
				font-size: 1.5rem;
				font-weight: 300;
			}
		}

		.graphic {
			&__pie {
				&-wrapper {
					display: flex;
					padding: 1rem;
					gap: 1rem;
				}

				&-label {
					width: 50%;
					font-size: 1.5rem;

					div {
						margin-bottom: 1rem;

						span {
							display: block;
							font-size: 1.2rem;
						}
					}
				}

				&-pie {
					max-width: 50%;

					canvas {
						width: 100%;
						max-height: 20rem;

						@media (min-width: 1200px) {
							max-height: 13rem;
						}
					}
				}
			}
		}
	}

	.selected {
		border: .3rem solid;

		@include themed($themes) {
			border-color: t("border-bright");
		}
	}
}
