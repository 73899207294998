@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700&display=swap");
@import "icomoon";
@import "typography";

.offline {
  position: fixed;
  width: 35rem;
  margin: 0.5rem calc(50% - 17.5rem);
  padding: 0.5rem;
  text-align: center;
  border-radius: 0.3rem;

  @include themed($themes) {
    background-color: t("element-background");
    box-shadow: t("shadow") t("shadow-color");
  }

  svg {
    margin-right: 1rem;

    @include themed($themes) {
      color: t("text-red");
    }
  }
}

#root {
  position: relative;
  overflow: hidden;
}

.popover {
  z-index: 2;

  &-arrow {
    &::after,
    &::before {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }

    &::before {
      border-width: 11px 10px 0;
      bottom: 0;
      left: -10px;

      @include themed($themes) {
        border-top-color: t("border-theme");
      }
    }

    &::after {
      border-width: 11px 10px 0;
      bottom: 1px;
      left: -10px;

      @include themed($themes) {
        border-top-color: t("element-background");
      }
    }
  }
}

.loading--app {
  display: flex;
  height: 100vh;
  padding: 1rem;
  overflow: auto;

  .loading {
    width: 25%;
    height: auto;
    margin: auto auto;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke-dasharray: 187;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }
  }

  &.theme--light {
    background: $light-theme-color;
    background: linear-gradient(
      45deg,
      rgba($light-theme-color, 0.5) 0%,
      rgba($light-theme-color, 1) 50%,
      rgba($light-theme-color, 0.5) 100%
    );

    .loading svg path {
      animation-name: load-light;
    }
  }

  &.theme--dark {
    background: $dark-theme-color;
    background: linear-gradient(
      45deg,
      rgba($dark-theme-color, 1) 0%,
      rgba($dark-theme-color, 0.9) 50%,
      rgba($dark-theme-color, 1) 100%
    );

    .loading svg path {
      animation-name: load-dark;
    }
  }
}

@keyframes load-light {
  100% {
    fill: rgba(255, 255, 255, 0.8);
  }
  0% {
    fill: rgba(255, 255, 255, 0.4);
  }
}

@keyframes load-dark {
  0% {
    fill: rgba(39, 27, 35, 0.8);
  }
  100% {
    fill: rgba(39, 27, 35, 0.4);
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: $dark-text;
}

::-webkit-scrollbar {
  border-radius: 10px;
  width: 0.7rem;
  background-color: $dark-text;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  @include themed($themes) {
    background-color: t("scrollbar-color");
  }
}


.mt-5 {
  margin-top: 5rem;
}
