@import "assets/scss/theme";

.facturation {
  .btn--secondary.active {
    @include themed($themes) {
      background-color: t("btn-primary-color");
      border-color: t("btn-primary-color");
      color: t("btn-primary-text-color");
    }

    &:not(:disabled):not(.disabled):not(.success):not(.error):hover {
      @include themed($themes) {
        background-color: t("btn-hover-color");
        border-color: t("btn-hover-color");
      }
    }
  }
}

.table.planning {
  text-align: left;

  td, th {
    padding: 0 .5rem;
    vertical-align: middle;
  }

  .border-left {
    border-left-style: solid;
    border-left-width: .2rem;
  }

  .center {
    text-align: center;
  }

  .NOK {
    @include themed($themes) {
      background: t("toast-background-danger");
      color: t("toast-text-danger");
    }
  }

  .OK {
    @include themed($themes) {
      background: t("toast-background-warning");
      color: t("toast-text-warning");
    }
  }

  .DONE {
    @include themed($themes) {
      background: t("toast-background-success");
      color: t("toast-text-success");
    }
  }
}

