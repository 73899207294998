@import 'assets/scss/theme';

.user-selector {
  display: flex;
  width: 100%;
  gap: 2%;

  label {
    @include themed($themes) {
      color: t('text-theme');
    }

    display: flex;
    justify-content: space-between;
    font-family: $font-montserrat, sans-serif;
    background: none;
    font-size: 0.9em;
    font-weight: 500;
    margin-left: 10px;
  }

  &__select {
    width: 50%;

    &--shortcut {
      display: flex;
      flex-wrap: wrap;

      .btn__group {
        padding: 0.5rem 0;
        width: 100%;
      }
    }

    .form-field__control input {
      text-align: center;
    }

    &--dropdown {
      text-align: center;
      max-height: 19rem;
      overflow: auto;

      &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border: .1rem solid;
        border-radius: .5rem;
        height: 4rem;
        padding: 0;
        margin: 0 0 .7rem 0;
        cursor: pointer;

        @include themed($themes) {
          box-shadow: t("shadow") t("shadow-color");
          color: t("text");
          border-color: t("border-gray");
          background-color: t("element-background");
        }

        &:hover {
          @include themed($themes) {
            background: t("hover-background");
          }
        }

        &.selected {
          @include themed($themes) {
            box-shadow: t("shadow") t("shadow-color");
            color: t("text-green");
            border-color: t("border-green");
            background-color: t("background-green");
          }
        }

        svg {
          position: absolute;
          left: 1.5rem;
        }
      }
    }
  }

  &__selected {
    width: 50%;

    &--container {
      max-height: 30rem;
      overflow-y: auto;
      overflow-x: hidden;
      display: grid;
      grid-template-columns: repeat(auto-fill, calc((100% - .7rem) / 2));
      grid-gap: .35rem .7rem;

      &--item {
        border: 1px solid;
        border-radius: .3rem;
        padding: .6rem;
        margin: .3rem 0;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;

        @include themed($themes) {
          background-color: t("theme-color");
          border-color: t("theme-color");
          color: t("text-white");
          box-shadow: 0.5rem 0.35rem 0.35rem t("shadow-color");
        }

        &:hover {
          @include themed($themes) {
            color: t("secondary-theme-color");
          }
        }

        &.accepted {
          @include themed($themes) {
            background-color: t("text-green");
            border-color: t("text-green");
            color: t("text-white");
          }
        }

        &.declined {
          @include themed($themes) {
            background-color: t("text-red");
            border-color: t("text-red");
            color: t("text-white");
          }
        }
      }
    }
  }
}

.add-employee {
  display: flex;
  flex-direction: column;

  h2 {
    width: 100%;
    margin: 3rem 0 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: .8rem;

    &--item {
      display: flex;
      flex: 30% 1 0;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 2.8rem;
      border-radius: .3rem;
      border: 0.1rem solid;
      cursor: pointer;

      @include themed($themes) {
        background-color: t("element-background");
        border-color: t("border-gray");
        box-shadow: t("shadow") t("shadow-color");
      }

      &:hover {
        @include themed($themes) {
          background: t("hover-background");
        }
      }
    }
  }

}
