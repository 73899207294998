@import "theme";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

span[aria-hidden="true"] {
  visibility: hidden;
  position: absolute;
}

img {
  @include themed($themes) {
    opacity: t("image-opacity");
  }
}

html {
  font-family: $font-lato;
  font-size: 62.5%;
  margin: 0;
  height: 100vh;
}

body {
  font-size: 1.4rem;
  line-height: 1.3;
  font-weight: 400;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
}

.ketchup-base {
  scroll-padding-top: 30rem;
  height: 100vh;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @include base($themes) {
    background-color: t("background");
    color: t("text");
  }
}

.button_position_right {
  border: none;
  position: absolute;
  width: 100%;
  bottom: 1rem;
  right: 0rem;
}

a:not(.btn):not(.navigation__nav-link) {
  text-decoration: none;
  transition: all 0.3s ease-in;
  cursor: pointer;

  @include themed($themes) {
    color: t("text-link");
  }

  &:visited {
    @include themed($themes) {
      color: t("text-link");
    }
  }

  &:hover,
  &.active {
    @include themed($themes) {
      color: t("text-link-hover");
    }
  }

  &:active {
    @include themed($themes) {
      color: t("text-link");
    }
  }
}

p {
  margin-bottom: 1.8rem;
}

h1,
.h1 {
  font-size: 3.6rem;
  font-weight: 300;
  margin: 0 0 6rem;
  line-height: 1.2;

  @include themed($themes) {
    color: t("text-theme");
  }

  div {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.2;

    @include themed($themes) {
      color: t("text");
    }
  }

  @media screen and (min-width: 768px) {
    font-size: 4.8rem;
    margin-top: 3rem;
  }
}

h2,
.h2 {
  font-size: 1.5em;
  font-weight: 300;
  margin: 0 0 1.8rem;

  @include themed($themes) {
    color: t("text-theme");
  }
}

h3,
.h3 {
  font-size: 1.1em;
  font-weight: 500;
  margin: 0 0 1rem;

  @include themed($themes) {
    color: t("text-theme");
  }
}

h4,
.h4 {
  font-size: .9em;
  font-weight: 600;
  margin: 0 0;

  @include themed($themes) {
    color: t("text-theme");
  }
}

.social-media {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0 0;
  display: flex;
  justify-content: flex-start;

  li {
    &.teams {
      &:not(.single) {
        margin-right: 1.7rem;
      }

      svg {
        width: 2.75rem;
      }
    }

    a {
      .fa-layers {
        width: 2.5rem;
        margin-right: 0.7rem;
        font-size: 2.5rem;

        .fa-square {
          font-size: 2.5rem;

          @include themed($themes) {
            color: t("socials-secondary");
          }
        }
      }

      &.Facebook {
        @include themed($themes) {
          color: t("socials-facebook");
        }
      }

      &.Twitter {
        @include themed($themes) {
          color: t("socials-twitter");
        }
      }

      &.LinkedIn {
        @include themed($themes) {
          color: t("socials-linkedin");
        }
      }

      &.Instagram {
        @include themed($themes) {
          color: t("socials-instagram");
        }
      }

      &.YouTube {
        @include themed($themes) {
          color: t("socials-youtube");
        }
      }

      &.Teams {
        path {
          &:first-of-type {
            @include themed($themes) {
              fill: t("socials-teams");
            }
          }

          &:last-of-type {
            @include themed($themes) {
              fill: t("socials-secondary");
            }
          }
        }
      }
    }
  }
}

.table--wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

table:not(.rdp-table) {
  width: 100%;

  border-collapse: collapse;
  border: none;
  border-spacing: 0;

  tr {
    vertical-align: top;

    &:last-child td {
      //border-width: 2px;
    }
  }

  thead {
    th {
      border-width: 2px;

      &.sort {
        cursor: pointer;
      }

      > div {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        margin-left: 1.5rem;
        height: 1.4rem;

        @include themed($themes) {
          color: t("text-gray");
        }

        .active {
          @include themed($themes) {
            color: t("text-theme");
          }
        }

        svg {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

  td,
  th {
    @include themed($themes) {
      border-color: t("theme-color");
    }

    text-align: left;
    padding: 0.3rem 0.3rem;
    border-bottom: 1px solid;

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }

    &.linebreak {
      white-space: pre-line;
    }
  }

  tfoot {
    td,
    th {
      border-top: 2px solid;
      font-weight: bold;
    }
  }

  .empty-list {
    margin: 2.5rem;
  }
}

.gray {
  @include themed($themes) {
    color: t("text-gray");
  }
}

.green {
  @include themed($themes) {
    color: t("text-green");
  }
}

.red {
  @include themed($themes) {
    color: t("text-red");
  }
}