@import '../../../../assets/scss/theme';

.form-field__control .editor {
  &__toolbar {
    margin: -.6rem -1rem 0;
    border-radius: .6rem .6rem 0 0;
    padding: .3rem .5rem;
    gap: .3rem;
    border: none;
    font-size: 1.4rem;

    @include themed($themes) {
      background: t("theme-color");
    }

    .inline-element {
      display: inline-flex;
      justify-content: space-between;
      align-items: stretch;

      button {
        border: .1rem solid;
        margin-left: -.1rem;

        @include themed($themes) {
          background: t("btn-primary-text-color");
          color: t("btn-primary-color");
          border-color: t("btn-primary-color")
        }

        &.active {
          background: #5d4155;
          color: #ffffff;
        }

        &:first-child {
          border-top-left-radius: 0.3rem;
          border-bottom-left-radius: 0.3rem;
        }

        &:last-child {
          border-top-right-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem;
        }

        &:disabled,
        &:disabled:hover {
          cursor: not-allowed;

          @include themed($themes) {
            color: t("text-gray");
            background: t("input-disabled");
          }
        }
      }

      .select .select__control {
        @include themed($themes) {
          background: t("btn-primary-text-color");
          border-color: t("btn-primary-color")
        }

        .select__value-container div {
          @include themed($themes) {
            color: t("btn-primary-color") !important;
          }
        }

        .select__indicators {
          .select__indicator.select__dropdown-indicator {
            @include themed($themes) {
              color: t("btn-primary-color");
            }
          }

          .select__indicator-separator {
            @include themed($themes) {
              background-color: t("btn-primary-color");
            }
          }
        }
      }
    }
  }

  &__wrapper {
    width: 100%;
    height: 50rem;

    .public-DraftEditorPlaceholder-root {
      @include themed($themes) {
        color: t('text-gray');
      }

      font-family: 'Montserrat', sans-serif;
      font-weight: 200;
      font-size: 1.6rem;
    }

    .public-DraftEditor-content {
      @include themed($themes) {
        color: t('text');
      }

      font-family: $font-lato, sans-serif;
      font-size: 18px;
      font-weight: 400;
    }
  }

  &__content {
    padding: 0;
    height: calc(100% - 3.42rem);
  }
}
