@import 'assets/scss/theme';

.graphic {
  margin-bottom: 1.5rem;

  &__bar {
    &-wrapper {
      display: flex;

      &--label {
        min-width: 3.5rem;
        max-width: 10rem;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 0.5rem;
        text-overflow: ellipsis;
      }
    }

    &-bar {
      position: relative;
      width: 100%;
      height: 2rem;
      margin-bottom: 1rem;

      @include themed($themes) {
        background: t('lightest-gray');
        color: t('text-theme');
      }



      &--value {
        position: absolute;
        height: 100%;

        @include themed($themes) {
          background: t('theme-color');
        }

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            @include themed($themes) {
              //background-color: t('graph' + $i);
            }
          }
        }

        &.success {
          @include themed($themes) {
            background: t('green-color');
          }
        }

        &.warning {
          @include themed($themes) {
            background: t('orange-color');
          }
        }

        &.danger {
          @include themed($themes) {
            background: t('red-color');
          }
        }
      }

      &--percent {
        position: absolute;
        font-size: 1.2rem;
        padding: .2rem 0;
        margin: 0 1rem;

        @include themed($themes) {
          color: t('text');
        }

        &.inverted {
          @include themed($themes) {
            color: t('text-white');
          }
        }
      }
    }
  }
}