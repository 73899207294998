@import "./src/assets/scss/variables";
@import url("https://fonts.googleapis.com/css?family=Indie+Flower");

.error-wrapper {
  background-repeat: no-repeat;
  background-position: right top;
  background-color: #e4e5e6;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-bottom: 2rem;

  &.theme--light {
    background-color: $light-background;

    h4,
    p {
      color: $light-text;
    }
  }

  &.theme--dark {
    background-color: $dark-background;

    h4,
    p {
      color: $dark-text;
    }
  }

  h1 {
    font-size: 13.5rem;
  }

  h4 {
    font-family: "Indie Flower", cursive;
    font-size: 1.6rem;
    margin-bottom: -2.5rem;
    margin-left: 2.5rem;
  }

  p {
    line-height: normal;
    margin: 0;
  }

  .btn {
    position: fixed;
    top: 2.5rem;
    width: 36rem;
    right: calc((100vw - 54rem) / 2);
    span {
      font-size: 16px;
    }

    svg {
      margin-right: 1rem;
    }
  }

  @media (max-height: 330px) {
    display: block;
    overflow: auto;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 16.5rem;
    }

    .btn {
      bottom: calc((100vh - 37.2rem) / 2);
      right: calc((100vw - 72rem) / 2);
      top: unset;
    }
  }

  @media (max-width: 390px) {
    .btn {
      width: 100%;
      font-size: 1.6rem;
      padding: 0.2rem 0;
    }
  }

  @media (min-height: 450px) {
    .btn {
      position: relative;
      margin-top: 30px;
      top: unset;
      height: auto;
      padding: 1rem 0;
      bottom: unset;
      right: unset;
      left: unset;
    }
  }

}