@import "assets/scss/theme";

.roles {
  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: .8rem;
    margin-bottom: 2rem;
  }

  &__user {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .5rem 1rem;
    height: 100%;
    width: 100%;
    align-items: center;
    border-radius: .3rem;
    border: 0.1rem solid;
    cursor: pointer;

    @media (min-width: 576px) {
      width: calc((100% - .8rem) / 2);
    }

    @media (min-width: 768px) {
      width: calc((100% - 1.6rem) / 3);
    }

    @media (min-width: 992px) {
      width: calc((100% - 2.4rem) / 4);
    }

    @media (min-width: 1200px) {
      width: calc((100% - 3.2rem) / 5);
    }

    > span {
      max-width: calc(100% - 2rem);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include themed($themes) {
        color: t("text");
      }
    }

    @include themed($themes) {
      background-color: t("element-background");
      border-color: t("border-gray");
      box-shadow: t("shadow") t("shadow-color");
    }

    &:hover {
      @include themed($themes) {
        background: t("hover-background");
      }
    }
  }

  .empty-list {
    margin: 0 0 1.5rem;
    text-align: left;
    font-size: 2rem;
  }
}
