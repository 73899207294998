@font-face {
  font-family: "icomoon";
  src: url("../icons/icomoon.woff") format("woff"),
    url("../icons/icomoon.ttf") format("truetype"),
    url("../icons/icomoon.eot") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-up:before {
  content: "\e902";
}
.icon-ketchup_heartbeat:before {
  content: "\e901";
}
.icon-ketchup_plain:before {
  content: "\e900";
}

.icon-checkbox:before {
  content: "\e92b";
}
.icon-number:before {
  content: "\e92c";
}
.icon-radio:before {
  content: "\e92d";
}
.icon-select:before {
  content: "\e92e";
}
.icon-text:before {
  content: "\e92f";
}
.icon-textarea:before {
  content: "\e930";
}
.icon-toggle:before {
  content: "\e931";
}
