@import "assets/scss/theme";

.admin {
    main {
        margin: 5.5rem 0 0 5.5rem;
        padding: 3rem;
        transition: all 0.3s ease-in-out;
        z-index: 1015;
    }

    &.busy {
        &:before {
            content: '';
            display: block;
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            backdrop-filter: blur(2px);
            z-index: 1020;

            @include themed($themes) {
                background-color: t("backdrop-busy");
            }
        }

        &:after {
            content: 'Een ogenblik geduld\AWe verwerken je verzoek';
            white-space: pre-wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: fixed;
            width: 100vw;
            z-index: 1021;

            font-size: 3rem;
            font-weight: 300;

            opacity: 0;
            animation: fadeIn .5s ease-in-out 2s forwards;

            @include themed($themes) {
                color: t("text-theme");
            }
        }
    }
}
@keyframes fadeIn {
    to {
        opacity: 1; /* Eindig met volledige zichtbaarheid */
    }
}

@media (min-width: 991px) {
    .admin.sidebar-open .admin-content {
        margin-left: 225px;
    }
}
