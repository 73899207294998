@import "assets/scss/theme";

.admin-header {
  border-bottom: .2rem solid;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0;

  @include themed($themes) {
    border-color: t("border-theme");
  }

  h1 {
    border: none;
    margin: 0;
  }

  .actions {
    width: 100%;
    text-align: right;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;

    .actions {
      padding-bottom: 1rem;
      width: auto;
      text-align: right;
    }
  }
}

.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;

  flex-flow: row wrap;
  grid-gap: 0.5rem;

  .btn {
    flex: 1;

    @media (min-width: 768px) {
      flex: unset;
    }

    > span {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-right: 1rem;
      border-radius: .5rem;

      &.success {
        @include themed($themes) {
          background-color: t("text-green");
        }
      }

      &.warning {
        @include themed($themes) {
          background-color: t("text-orange");
        }
      }

      &.danger {
        @include themed($themes) {
          background-color: t("text-red");
        }
      }

      &.info {
        @include themed($themes) {
          background-color: t("text-blue");
        }
      }
    }
  }
}
