@import "assets/scss/theme";

.list-item {
  margin: 0 0 1em 0;
  border: 1px solid;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  padding: 0.5em 0;

  @include themed($themes) {
    background: t("element-background");
    border-color: t("border-theme");
    box-shadow: t("shadow") t("shadow-color");
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
    border-bottom: 1px solid;

    @include themed($themes) {
      color: t("text-theme");
    }

    h2 {
      margin: 0;
      width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__icons {
    font-size: 1.5rem;
    margin: 0 0 0 .3rem;
  }

  i {
    letter-spacing: 0.1em;
    padding:  0 .5rem;
  }
}
