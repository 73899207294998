@import "../../assets/scss/theme";

.picture {
	width: 7rem;
	height: 7rem;
	object-fit: cover;
	border-radius: 0.3rem;
	margin-right: 1rem;
	flex-basis: 7rem;

	&.rounded {
		border-radius: 50%;
	}

	&.with-shadow {
		@include themed($themes) {
			box-shadow: 0 0.3rem 0.6rem t("shadow-color");
		}
	}

	@media (min-width: 768px) {
		width: 15rem;
		height: 15rem;
		flex-basis: 15rem;
	}

	@media (min-width: 992px) {
		width: 17rem;
		height: 17rem;
		flex-basis: 17rem;
	}

	@media (min-width: 1200px) {
		width: 20rem;
		height: 20rem;
		flex-basis: 20rem;
	}
}

div.picture {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 5rem;

	@include themed($themes) {
		background-color: t("background");
		color: t("theme-color");
	}

	@media (min-width: 768px) {
		font-size: 7rem;
	}

	@media (min-width: 992px) {
		font-size: 9rem;
	}

	@media (min-width: 1200px) {
		font-size: 10rem;
	}
}

.background-picture {
	width: 100%;
	height: 100%;
}