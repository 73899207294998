@import "../../assets/scss/theme";

.loading {
  margin: auto;
  .icon {
    width: 18.3rem;
    height: 5rem;
    margin: 5rem auto;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke-dasharray: 187;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        @include themed($themes) {
          animation-name: t("color-animation");
        }
      }
    }

    .ellipsis {
      font-size: 3rem;
      margin-top: 2rem;
      font-weight: 500;

      @include themed($themes) {
        color: t("text-theme");
      }

      animation-timing-function: linear;
      animation-duration: 2s;
      animation-name: blink;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 1500ms infinite;
        content: "\2026";
        width: 0;
      }
    }
  }

  &.centered {
    display: flex;
    height: 100vh;

    .icon {
      margin: auto auto;
    }
  }

  &.large {
    .icon {
      width: 20%;
      height: auto;
    }
  }
}

@keyframes colors-light {
  0% {
    fill: rgba(131, 94, 130, 0.4);
  }
  100% {
    fill: rgba(131, 94, 130, 0.1);
  }
}

@keyframes colors-dark {
  0% {
    fill: rgba(65, 47, 59, 0.4);
  }
  100% {
    fill: rgba(65, 47, 59, 0.2);
  }
}

@keyframes ellipsis {
  to {
    width: 1em;
  }
}
