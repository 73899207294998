@import 'assets/scss/theme';

.timepicker {
    width: 3em;
    position: relative;

    .display {
        @include themed($themes) {
            color: t('text');
        }

        padding: 0;
        width: 100%;
        height: 100%;
    }

    .empty {
        @include themed($themes) {
            color: t('text-gray');
        }
    }

    .dropdown {
        cursor: pointer;
        position: absolute;
        width: 100%;
        left: 0;
        opacity: 0;
    }

    &.disabled .dropdown {
        cursor: not-allowed;
    }

}