$font-lato: "Lato", sans-serif;
$font-montserrat: "Montserrat", sans-serif;

$light-background: #f3eef1;
$opaque-light-background: rgba(243, 238, 241, 0.7);

$light-element-background: #ffffff;
$transparant-news-background:rgba(0, 0, 0, 0.5);
$light-theme-color: #835e78;
$darker-theme-color: #5d4155;
$light-text: #333333;
$white-text:#ffffff;
$light-gray: #c6c6c6;
$lighter-gray: #3e25257f;
$lightest-gray: #e4e3e2;
$darkest-gray: #75716d;
$rose-quartz: #dbcdd7;
$dark-mauve: #3d2c38;
$light-login-modal-background: #fafafa;
$dark-login-modal-background: #3c2f38;
$light-shadow: #E6DFE4;
$dark-shadow: #450f43;

$light-red: #ab182d;
$light-red-medium: #ea6c7e;
$light-red-background: #fceef0;
$light-red-button: #911426;
$light-green-button: #499834;
$light-green: #57b33e;
$light-green-medium: #7fea6c;
$light-green-background: #f0fcee;
$light-orange: #ef7d00;
$light-orange-medium: #ffba6f;
$light-orange-background: #fff7ee;
$light-blue: #27348b;
$light-blue-background: #eff0fa;
$light-blue-medium: #7582d8;
$light-group: #9d9694;
$light-group-background: #f9f8f8;
$light-group-medium: #cbc7c6;

$light-teams: #5558af;
$light-facebook: #3b5998;
$light-twitter: #000000;
$light-linkedin: #0077b5;
$light-instagram: #e1306c;
$light-youtube: #ff0000;

$dark-background: #2d1e27;
$opaque-dark-background: rgba(26, 18, 23, 0.7);

$dark-element-background: #271b23;
$dark-theme-color: #412f3b;
$dark-text: #cfcacd;
$dark-gray: #c6c6c6;
$darker-gray: #828282;

$dark-red-button: #771524;
$dark-red: #660e1b;
$dark-red-medium: #4c0a14;
$dark-red-background: #29070c;
$lighter-dark-red-background: #751423;
$dark-green-button: #317126;
$dark-green: #1b660e;
$dark-green-medium: #144c0a;
$dark-green-background: #0c2907;
$dark-orange: #8f4b00;
$dark-orange-medium: #6b3800;
$dark-orange-background: #311a00;
$dark-blue: #2e3da4;
$dark-blue-background: #0b0d25;
$dark-blue-medium: #1d2668;
$dark-group: #4f4a48;
$dark-group-background: #f9f8f8;
$dark-group-medium: #cbc7c6;

$dark-teams: darken($light-teams, 15);
$dark-facebook: darken($light-facebook, 15);
$dark-twitter: #000000;
$dark-linkedin: darken($light-linkedin, 15);
$dark-instagram: darken($light-instagram, 25);
$dark-youtube: darken($light-youtube, 25);

$light-timesheet-item: #e4eee2;
$dark-timesheet-item: #1d2d1c;

$black: #333333;
$white: #ffffff;
$gray: #655f5d;

$moving-mission-gold: #ffdf00;
$moving-mission-silver: #C0C0C0;
$moving-mission-bronze: #CD7F32;

$moving-mission-gold-dark: #b29600;
$moving-mission-silver-dark: #868686;
$moving-mission-bronze-dark: #8f5822;

$lightbox-backdrop-light: rgba(243, 238, 241, 0.8);
$lightbox-backdrop-dark: rgba(0, 0, 0, 0.8);

$bright-green-light: #08FF00;
$bright-green-dark: #037f00;



$graph1-light: #FF6347;
$graph2-light: #8C52FF;
$graph3-light: #FFD700;
$graph4-light: #ADFF2F;
$graph5-light: #FF69B4;
$graph6-light: #4B0082;
$graph7-light: #FF4500;
$graph8-light: #7CFC00;
$graph9-light: #DB7093;
$graph10-light: #FFA07A;
$graph11-light: #20B2AA;
$graph12-light: #87CEFA;
$graph13-light: #778899;
$graph14-light: #1ca31e;
$graph15-light: #FF00FF;
$graph16-light: #FF5757;
$graph17-light: #800000;
$graph18-light: #008080;

$grid-columns: 12;
$gutter: 15;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
) !default;
$container-max-widths: (
        sm: 90vw,
        md: 55vw,
        lg: 55vw,
        xl: 60vw,
) !default;
$main-top-margins: (
        sm: 98px + 25px,
        md: 118px + 25px,
        lg: 133px + 25px,
        xl: 148px + 25px,
) !default;
