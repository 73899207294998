@import 'assets/scss/theme';

.form-builder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__available {
    padding: 1rem;
    margin-bottom: 1.5rem;

    @include themed($themes) {
      background: t("element-background");
      box-shadow: inset t("shadow") t("shadow-color");
    }

    &-field {
      opacity: 1;
      padding: .5rem;
      border: .1rem dashed;
      margin-bottom: .5rem;
      cursor: pointer;
      display: flex;
      align-items: center;


      &.dragging {
        opacity: .5;
      }

      svg {
        width: 2rem;
        margin-right: .5rem;
      }
    }
  }

  &__preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    gap: 1rem;

    &-container {
      height: calc(100vh - 21.7rem);
      padding: 1.5em;
      overflow: hidden;

      p {
        text-align: center;
        font-size: 2.5rem;
        font-weight: 300;
        margin: 10rem 0;
        color: #c6c6c6;
      }

      > div {
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      @include themed($themes) {
        background: t("element-background");
        box-shadow: t("shadow") t("shadow-color");
      }

      &--item {
        padding: .5rem;
        border: .1rem solid;
        margin-bottom: .5rem;
        opacity: 1;

        @include themed($themes) {
          border-color: t("border-theme");
        }

        &.dragging {
          opacity: 0;
        }

        &__header {
          display: flex;
          position: relative;
          padding-bottom: 1rem;

          svg {
            width: 2rem;
            margin-right: .5rem;
          }

          .btn__group {
            position: absolute;
            right: -.1rem;
            top: -.6rem;
            opacity: .2;
            transition: all 0.3s ease-in;

            &:hover {
              opacity: 1;
            }

            svg {
              margin: 0;
            }
          }
        }
      }
    }

    &-remove {
      width: 100%;
      border: .1rem dashed;
      padding: 1rem;
      text-align: center;
      font-weight: bold;

      @include themed($themes) {
        border-color: t("border-red");
        color: t("text-red");
      }
    }
  }
}