@import "assets/scss/theme";

.permissions {
  &__header {
    border-bottom: 2px solid;
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    padding: 0.5em 0;
    @include themed($themes) {
      border-color: t("theme-color");
    }
    * {
      margin: 0;
    }
    .name {
      width: 30%;
      @include themed($themes) {
        color: t("text");
      }
    }
    .role {
      border-left: 1px solid;
      border-right: 1px solid;
      width: 10%;
      text-align: center;
      @include themed($themes) {
        color: t("text");
        border-color: t("border-gray");
      }
    }
  }

  &__item {
    border-bottom: 1px solid;
    padding: 0.75em 0em;
    display: flex;
    flex-wrap: wrap;
    .description-container {
      width: 30%;
      .name {
        letter-spacing: 0.1em;
        font-weight: bold;
        @include themed($themes) {
          color: t("text-theme");
        }
      }
      .description {
        width: 25em;
        font-size: 0.9em;
        font-style: italic;
        letter-spacing: 0.05em;
        @include themed($themes) {
          color: t("text");
        }
      }
    }
    p {
      margin: 0;
    }
    .checkbox-container {
      width: 10%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  &__row {
    border: 1px solid;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__labels {
    border: 1px solid;
  }
  &__inputs {
    border: 1px solid;
  }
}
