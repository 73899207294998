@import "../../../assets/scss/theme";

.navigation {
	@include themed($themes) {
		background: t("sidebar-backcolor");
		color: t("sidebar-text");
	}

	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 5.5rem;
	height: calc(100vh - 5.5rem);
	top: 5.5rem;
	left: 0;
	z-index: 1020;
	transition: all 0.3s ease-in-out;

	&__handle {
		@include themed($themes) {
			background: t("sidebar-handle");
			color:t('sidebar-text');
		}

		padding: 1.3rem 2.3rem;
		text-align: right;
		border: none;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		svg {
			transition: all 0.3s ease-in-out;
		}

		&:focus {
			box-shadow: none;
			outline: none;
		}

		&:hover {
			@include themed($themes) {
				background: t("sidebar-handle-hover");
			}
		}
	}

	&__nav {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;

		&-item {
			position: relative;
			margin: 0;
		}

		&-link {
			width: 100%;
			display: flex;
			justify-content: center;
			padding: 1.2rem 1.6rem;
			font-size: 1.4rem;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			text-decoration: none;
			background: 0 0;

			@include themed($themes) {
				color: t("sidebar-text");
			}

			> span {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				width: 0;
				transition: width 0.3s ease-in-out;
			}

			.icon {
				@include themed($themes) {
					color: t("sidebar-text");
				}

				text-align: center;
				transition: all 0.3s ease-in-out;
			}

			&.active {
				@include themed($themes) {
					background-color: t("sidebar-active-back");
					color: t("sidebar-text");
				}

				.icon {
					@include themed($themes) {
						color: t("sidebar-active");
					}
				}
			}

			&:hover:not(.active) {
				@include themed($themes) {
					background-color: t("sidebar-active");
					color: t("sidebar-text");
				}

				.icon {
					@include themed($themes) {
						color: t("sidebar-text");
					}
				}
			}
		}
	}

	&.is_open {
		width: 22.5rem;

		.navigation {
			&__handle {
				transition: all 0.3s ease-in-out;

				svg {
					transform: rotate(-3.142rad);
				}
			}

			&__nav {
				&-link {
					justify-content: space-between;

					> span {
						transition: width 0.3s ease-in-out;
						width: 16rem;
					}
				}
			}
		}
	}
}
