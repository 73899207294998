@import "assets/scss/theme";

.mailbox {
  .card {
    &__content_wrapper {
      padding: 0;
    }

    &__body {
      padding: 0;
    }
  }


  &__navigation {
    border-bottom: .1rem solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include themed($themes) {
      border-color: t('border-gray');
    }

    ul {
      list-style: none;
      margin: 0.5rem 0;
      padding: 0;

      &:last-child {
        margin-top: 5rem;
      }

      li {
        padding-right: 2rem;

        a {
          display: block;
          padding: 1.5rem 0 1.5rem 1.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;

          @include themed($themes) {
            color: t('text');
          }

          svg {
            transition: all 0.3s ease-in;
            margin-right: 1rem;

            @include themed($themes) {
              color: t('hover-background');
            }
          }

          &.active {
            border-radius: 0 .5rem .5rem 0;

            @include themed($themes) {
              background: t('hover-background');
            }

            svg {
              @include themed($themes) {
                color: t('text-theme');
              }
            }
          }
        }
      }
    }

    @media (min-width: 576px) {
      border-right: 1px solid;
      border-bottom: none;

      ul {
        li {
          padding-right: 0;
        }
      }
    }
  }

  &__content {
    padding: 1.5rem 3rem;

    @media (min-width: 576px) {
      padding: 1.5rem 3rem 1.5rem 1.5rem;
    }
  }

  &__messages {
    padding: 0;
    list-style: none;
    border-top: .1rem solid;

    @include themed($themes) {
      border-color: t('border-gray');
    }

    &--message {
      position: relative;
      padding: 1rem 1rem 1rem 1rem;
      cursor: pointer;
      border-bottom: .1rem solid;

      @include themed($themes) {
        border-color: t('border-gray');
      }

      .actions {
        position: absolute;
        left: 0;
        display: flex;
        flex-direction: column;

        .action {
          width: 2rem;
          margin-bottom: .5rem;
          text-align: center;

          @include themed($themes) {
            color: t('text-theme');
          }
        }
      }

      .header {
        display: flex;
        flex-direction: row;
        margin-bottom: .5rem;
        justify-content: space-between;

        .date svg {
          margin-right: .5rem;
        }
      }

      .title {
        margin-bottom: .5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .description {
        font-size: 1.2rem;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        @include themed($themes) {
          background: t('hover-background');
        }
      }
    }
  }

  &__email {
    &--header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1.5rem;
      border-bottom: .1rem solid;

      @include themed($themes) {
        border-color: t('border-gray');
      }
    }

    &--from {
      &_name {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    &--date svg {
      margin-right: .5rem;
    }

    &--to {
      display: flex;
      padding: 1.5rem 0;

      border-bottom: .1rem solid;

      @include themed($themes) {
        border-color: t('border-gray');
      }

      &_label {
        margin-right: 1rem;
        font-weight: 600;
      }

      &_names {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        > div {
          padding: 0 .5rem;
          border-radius: .5rem;

          &:hover {
            @include themed($themes) {
              background: t('hover-background');
            }
          }
        }
      }
    }
  }
}

.select {
  &__label {
    cursor: pointer;
  }

  &__status {
    &--indicator {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 10px;
      border-radius: 10px;

      &.success {
        @include themed($themes) {
          background: t('green-color');
        }
      }

      &.warning {
        @include themed($themes) {
          background: t('orange-color');
        }
      }

      &.danger {
        @include themed($themes) {
          background: t('red-color');
        }
      }
    }
  }
}