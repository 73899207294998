@import 'assets/scss/theme';

.form-group-description {
    @include themed($themes) {
        color: t('text');
    }

    font-family: $font-montserrat, sans-serif;
    font-weight: 200;
    font-size: 0.9em;
    margin-left: 10px;
}