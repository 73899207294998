@import '../../assets/scss/theme';

.attachments {
    @include themed($themes) {
        background-color: t('element-background');
        border-color: t('theme-color');
        color: t('text-theme');
    }

    border: 1px solid;
    border-radius: 3px;
    padding: 1em;
    box-shadow: inset 2px 0 10px 0 rgba($light-theme-color, 0.3);

    .attachment {
        display: flex;
        justify-content: space-between;

        > span {
            display: flex;
            align-items: center;
            gap: 1em;
        }

        &:not(:last-child) {
            margin-bottom: 1em;
        }

        &:hover {
            cursor: pointer;
        }

        .icon {
            @include themed($themes) {
                color: t('text');
            }

            font-size: 1.5em;
        }

        .text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}