@import "assets/scss/theme";

.history {
    padding: 0 1rem 1rem;

    &__content {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        overflow: auto;
        height: 20rem;

        @media (min-width: 992px) {
            height: 29rem;
        }

        @media (min-width: 1200px) {
            height: 9.5rem;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__item {
        cursor: pointer;
        padding: .5rem;
        border-radius: 3px;

        @include themed($themes) {
            background: t("theme-color");
            color: t("text-white");
        }

        &-header {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            &-wrapper {
                display: flex;
                flex-direction: column;
                gap: .3rem;

                svg {
                    margin-right: 1rem;
                }
            }
        }

        &-content {
            padding: 1rem 0;

            .attachments {
                background-color: transparent;
                box-shadow: none;
                border: none;
                border-radius: 0;
                padding: 0;

                @include themed($themes) {
                    color: t("text-white");
                }

                .attachment {
                    > span {
                        gap: .5rem
                    }

                    .icon {
                        color: #FFFFFF;

                        @include themed($themes) {
                            color: t("text-white");
                        }
                    }
                }
            }
        }
    }
}
