@import "../../assets/scss/theme";

.modal {
  position: fixed;
  z-index: 999999 !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  backdrop-filter: blur(2px);

  @include themed($themes) {
    background-color: t("backdrop");
  }

  &__content {
    width: 90%;
    border: 0.1rem solid;
    border-radius: 0.3rem;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 50%;
    top: 50%;
    padding: 1rem;
    transform: translate(-50%, -50%);

    @include themed($themes) {
      background-color: t("element-background");
      border-color: t("border-theme");
    }

    section {
      display: flex;

      main {
        padding: 0;
        flex-basis: calc(100% - 7rem);
        flex-grow: 1;
      }
    }

    @media (min-width: 768px) {
      width: 70%;

      &.medium {
        width: 90%;
      }

      &.large {
        width: 90%;
      }

      &.small {
        width: 40%;
      }
    }

    @media (min-width: 992px) {
      width: 50%;

      &.medium {
        width: 70%;
      }

      &.large {
        width: 70%;
      }

      &.small {
        width: 40%;
      }
    }
  }

  &__header {
    position: relative;
    border-bottom: 0.2rem solid;

    @include themed($themes) {
      border-color: t("border-theme");
    }

    h2 {
      font-family: $font-montserrat;
      font-size: 1.9rem;
      font-weight: bold;
      margin: 0 0 0.5rem;

      @include themed($themes) {
        color: t("text-theme");
      }

      @media (min-width: 768px) {
        font-size: 2.2rem;
      }
    }

    h3 {
      font-family: $font-lato;
      font-size: 1.4rem;
      font-weight: normal;
      margin: 0 0 1rem;

      @include themed($themes) {
        color: t("text-theme");
      }

      @media (min-width: 992px) {
        font-size: 1rem;
      }
    }

    button {
      position: absolute;
      font-size: 1.6rem;
      text-decoration: none;
      background-color: transparent;
      border: none;
      padding: 0;
      top: 0;
      right: 0;
      cursor: pointer;

      @include themed($themes) {
        color: t("text-theme");
      }
    }

    &.close{
      border: none;
    }
  }

  &__body {
    max-height: 70vh;
    overflow: hidden auto;
    padding: 1.5rem 0;

    @include themed($themes) {
      background-color: t("element-background");
      color: t("text")
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    padding-top: 0.5em;
    text-align: right;

    @include themed($themes) {
      background-color: t("element-background");
    }
  }
}
