@import "assets/scss/theme";

.dashboard {
	&__shortcuts {
		display: flex;
		justify-content: center;
	}

	&__upcoming {
		margin-top: 3rem;

		&.statics {
			margin-top: 1rem;
		}

		.row {
			height: 100%;
		}

		.card {
			height: calc(100% - 1.5rem);

			@include themed($themes) {
				border-color: t("border-theme");
			}

			&__content_wrapper {
				padding: 0;
			}

			&__header {
				padding: 1rem;

				@include themed($themes) {
					background: t("theme-color");
				}

				h2 {
					font-size: 1.5rem;
					margin: 0;

					@include themed($themes) {
						color: t("text-white");
					}
				}
			}

			&__body {
				padding: 1rem;

			}
		}

		.content {
			display: flex;
			align-items: flex-start;

			@include themed($themes) {
				color: t("text");
			}

			b {
				width: 9rem;
			}
		}

		ol {
			list-style-position: inside;
			margin: 0;
			padding: 0;

			li {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: .2rem 0;

				a, div {
					flex-grow: 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin: 0 .5rem 0 1rem;
				}

				span {
					flex-shrink: 0;
					max-width: 5rem;
					text-align: right;
				}
			}
		}
	}

	&__statics {
		margin-top: 3rem;

		.static {
			&__one {
				display: flex;
				border-radius: .3rem;
				border: .1rem solid;
				margin-bottom: 1.5rem;
				height: 11rem;

				@include themed($themes) {
					background: t("element-background");
					border-color: t("border-theme");
					box-shadow: inset t("shadow") t("shadow-color");
				}

				&--logo {
					width: 11rem;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 5rem;
					border-radius: .3rem 0 0 .3rem;

					@include themed($themes) {
						background: t("theme-color");
						color: t("text-white");
					}
				}

				&--content {
					padding: 1rem;
					display: flex;
					flex-direction: column;
					justify-content: center;

					h3 {
						font-size: 2rem;
						margin: 0
					}
				}
			}

			&__two {
				display: flex;
				position: relative;
				border-radius: .3rem;
				margin-bottom: 1.5rem;
				height: calc((100% - 4.5rem) /3);
				min-height: 13.82rem;

				@include themed($themes) {
					background: t("theme-color");
					color: t("text-white");
					box-shadow: t("shadow") t("shadow-color");
				}

				&--logo {
					position: absolute;
					top: 2rem;
					right: 2rem;
					font-size: 3rem;
					opacity: .7;
				}

				&--content {
					padding: 1rem;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;

					h3 {
						font-size: 2rem;
						margin: 0;

						@include themed($themes) {
							color: t("text-white")
						}
					}
				}
			}
		}


	}

	&__stickers {
		margin-top: 3rem;

		svg {
			width: 2rem;
		}
	}

	&__visitors,
	&__table {
		margin-top: 3rem;
	}
}