@import "assets/scss/theme";

.theme-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem;

  @include themed($themes) {
    color: t("text");
  }

  svg {
    display: none;
  }

  &[data-mode="light"] {
    svg:first-child {
      display: block;
    }
  }

  &[data-mode="dark"] {
    svg:nth-child(2) {
      display: block;
    }
  }

  &[data-mode="auto"] {
    svg:nth-child(3) {
      display: block;
    }
  }
}
