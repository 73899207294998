@import "assets/scss/theme";
@import url("https://fonts.googleapis.com/css?family=Indie+Flower");

.notfound-error-wrapper {
  padding-bottom: 5.5rem;

  h1 {
    font-size: 13.5rem;
  }

  h4 {
    font-family: "Indie Flower", cursive;
    font-size: 1.6rem;
    margin-bottom: -2.5rem;
    margin-left: 2.5rem;
  }

  p {
    line-height: normal;
    margin: 0;
  }

  .btn {
    position: relative;
    margin-top: 30px;
    top: 2.5rem;
    height: auto;
    padding: 1rem 0;
    left: unset;
    width: 36rem;

    span {
      font-size: 16px;
    }

    svg {
      margin-right: 1rem;
    }
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 16.5rem;
    }
  }

  @media (max-width: 480px) {
    .btn {
      width: 100%;
      font-size: 1.6rem;
      padding: 0.2rem;
    }
  }

}