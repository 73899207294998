@import 'assets/scss/theme';

.card {
	display: flex;
	position: relative;
	flex-direction: column;
	margin-bottom: 1.5rem;
	border: 0.1rem solid;
	border-radius: 0.3rem;
	//height: calc(100% - 1.5rem);
	justify-content: space-between;

	@include themed($themes) {
		background: t('element-background');
		border-color: t('border-gray');
		color: t('text-theme');
	}

	&.shadow {
		@include themed($themes) {
			box-shadow: t('shadow') t('shadow-color');
		}
	}

	&__content {
		height: 100%;

		&_wrapper {
			position: relative;
			//min-height: 12rem;
			//max-height: 17rem;
			overflow-y: scroll;
			width: 100%;
			height: 100%;
			padding: 2rem;

			&::-webkit-scrollbar {
				display: none;
			}


			&__background {
				@include themed($themes) {
					color: t('text-background');
				}
				position: absolute;
				left: 65%;
				top: -15px;
				font-size: 15em;
			}

			@media (min-width: 768px) {
				//min-height: 10rem;
			}

			@media (min-width: 992px) {
				//min-height: 12rem;
			}

			@media (min-width: 1200px) {
				//min-height: 15rem;
			}
		}
	}

	&__header {
		h2,
		h3,
		h4 {
			font-family: 'Lato', sans-serif;
			margin: 0 0 0.5rem;

			@include themed($themes) {
				color: t('text-theme');
			}
		}

		h4 {
			font-size: 1rem;
			font-weight: 400;
		}

		h2 {
			font-family: 'Montserrat', sans-serif;
			font-size: 1.7rem;
			font-weight: 300;

			@media (min-width: 768px) {
				font-size: 2rem;
			}
		}

		h3 {
			font-size: 1.4rem;
			font-weight: normal;

			@media (min-width: 992px) {
				font-size: 1rem;
			}
		}

		svg {
			margin-right: 1rem;
		}

		&_wrapper {
			padding-right: 0.4rem;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	&__body {
		font-family: 'Lato', sans-serif;
		font-size: 1.4rem;
		font-weight: normal;
		padding: .5rem 0;

		@include themed($themes) {
			color: t('text');
		}

		@media (min-width: 768px) {
			font-size: 1.6rem;
		}
	}

	&__footer {
		@include themed($themes) {
			border-color: t('border-theme');
		}

		border-top: 1px solid;
		padding: 1.2rem 2rem;
		font-size: 1.2rem;

		.btn__group {
			margin: 0;
		}
	}

	&.with-read-more {
		.card {
			transition: all .3s ease-out;

			&__header_read-more {
				position: absolute;
				background-color: transparent;
				font-size: 1.2rem;
				border: none;
				padding: 0;
				top: .5rem;
				right: 0;
				cursor: pointer;
				z-index: 1;

				@include themed($themes) {
					color: t('text-theme');
				}
			}

			&__body {
				transition: all .3s ease-out;

				&.folded {
					max-height: 0;
					padding: 0;
					overflow: hidden;
				}

				&.unfolded {
					max-height: 40vh;
					padding: .5rem 0;
					overflow: auto;
				}

				p:first-of-type {
					margin-top: 0;
				}
			}

			&__read-more {
				position: absolute;
				background-color: transparent;
				font-size: 1rem;
				text-align: center;
				border: none;
				width: 100%;
				padding: 0;
				bottom: .5rem;
				left: 0;
				cursor: pointer;

				@include themed($themes) {
					color: t('text-theme');
				}
			}
		}
	}
}